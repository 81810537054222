<mat-toolbar data-cy="toolbar" color="primary">
  <div class="align-row">
    <button mat-button (click)="back()" *ngIf="enableBackButton">
      <mat-icon>home</mat-icon>
    </button>
    <div class="not-mobile">
      <button mat-button [routerLink]="['/case-list']" class="link">
        <img class="header-btn" src="assets/header.png" />
      </button>
    </div>
  </div>

  <div class="center">{{ title }}</div>

  <div class="right-aligned">
    <button
      data-cy="toolbar-menu-btn"
      mat-icon-button
      [matMenuTriggerFor]="menu"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <div class="menu-width"></div>
      <button [routerLink]="['/admin']" *ngIf="admin | async" mat-menu-item>
        <span>{{ "toolbar.admin" | translate }}</span>
      </button>
      <mat-divider *ngIf="admin | async"></mat-divider>
      <button [routerLink]="['/imprint']" fragment="about" mat-menu-item>
        <span>{{ "toolbar.about" | translate }}</span>
      </button>
      <button [routerLink]="['/imprint']" fragment="help" mat-menu-item>
        <span>{{ "toolbar.help" | translate }}</span>
      </button>
      <button
        [routerLink]="['/imprint']"
        fragment="imprint"
        data-cy="toolbar-imprint"
        mat-menu-item
      >
        <span>{{ "toolbar.imprint" | translate }}</span>
      </button>
      <button
        [routerLink]="['/imprint']"
        fragment="data-privacy"
        data-cy="toolbar-privacy"
        mat-menu-item
      >
        <span>{{ "toolbar.dataPrivacy" | translate }}</span>
      </button>
      <button
        [routerLink]="['/imprint']"
        fragment="copyright"
        data-cy="toolbar-copyright"
        mat-menu-item
      >
        <span>{{ "toolbar.termsOfService" | translate }}</span>
      </button>
      <mat-divider *ngIf="authenticated | async"></mat-divider>
      <button mat-menu-item *ngIf="authenticated | async" (click)="logout()">
        <span>{{ "toolbar.logout" | translate }}</span>
      </button>
    </mat-menu>
  </div>
</mat-toolbar>
