<app-content-container>
  <div class="grid">
    <div class="center main-body">
      <div class="default-card main-card left-card">
        <img src="assets/header_without_rvc.png" />
        <h1>{{ "login.hello-box.title" | translate }}</h1>
        <b>{{ "login.hello-box.bold-begin" | translate }}</b>
        {{ "login.hello-box.content" | translate }}
      </div>
    </div>
    <div class="center">
      <mat-card class="default-card" *ngIf="currState !== 'login'">
        <app-register *ngIf="currState === 'register'"></app-register>
        <app-reset-password
          *ngIf="currState === 'reset-password'"
        ></app-reset-password>
        <app-email-action
          *ngIf="currState === 'email-action'"
        ></app-email-action>
      </mat-card>
      <mat-card *ngIf="currState === 'login'" class="default-card">
        <mat-card-title class="login-title">{{
          "login.title" | translate
        }}</mat-card-title>
        <mat-card-content>
          <form [formGroup]="loginForm" class="center-text">
            <mat-form-field>
              <mat-label>{{ "login.email" | translate }}</mat-label>
              <input matInput formControlName="email" data-cy="email" />
              <mat-icon matSuffix>mail</mat-icon>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ "login.password" | translate }}</mat-label>
              <input
                matInput
                formControlName="password"
                type="password"
                data-cy="password"
              />
              <mat-icon matSuffix>lock</mat-icon>
            </mat-form-field>
            <button
              (click)="login()"
              [disabled]="!loginForm.valid"
              mat-flat-button
              color="primary"
              class="full-width login-btn"
              data-cy="submit"
            >
              {{ "login.submit" | translate | uppercase }}
            </button>
            <a
              [routerLink]="['/reset-password']"
              [queryParams]="{ email: this.loginForm.value.email }"
              class="display-block"
            >
              {{ "login.forgotpw" | translate }}
            </a>
            <div class="divider">
              <mat-divider></mat-divider>
            </div>
            <button
              mat-raised-button
              [routerLink]="['/register']"
              color="accent"
              [queryParams]="{ email: this.loginForm.value.email }"
              class="register-btn full-width"
            >
              {{ "login.register" | translate | uppercase }}
            </button>
            <br />
          </form>
          <br />
        </mat-card-content>
        <b data-cy="error-box-login" *ngIf="infoTextLogin | async">
          {{ infoTextLogin | async }}
        </b>
      </mat-card>
    </div>
  </div>
</app-content-container>
