<h1 mat-dialog-title>{{ data.titleText }}</h1>
<div mat-dialog-content>
  <p data-cy="confirm-dialog-desc">{{ data.descText }}</p>
</div>
<div mat-dialog-actions class="button-row">
  <button
    mat-raised-button
    data-cy="confirm-dialog-abort-btn"
    (click)="abort()"
  >
    {{ data.abortText }}
  </button>
  <button
    mat-raised-button
    data-cy="confirm-dialog-confirm-btn"
    (click)="confirm()"
    color="primary"
    cdkFocusInitial
  >
    {{ data.confirmText }}
  </button>
</div>
