<mat-expansion-panel data-cy="case-card">
  <mat-expansion-panel-header>
    <!--mat-panel-title data-cy="forename-surname"> </mat-panel-title>
    <mat-panel-description-->
    <div class="desc">
      <div class="name">
        <b data-cy="forename-surname">
          {{ case | cardTitlePipe }}
        </b>
        <p>
          {{ case.genUID }}
        </p>
      </div>
      <div class="fill-header" *ngIf="case.completed">
        <div class="chip green" *ngIf="risk === 'low'">
          {{ "case.risk.low" | translate }} ({{ score | scorePipe }})
        </div>
        <div class="chip amber" *ngIf="risk === 'medium'">
          {{ "case.risk.medium" | translate }} ({{ score | scorePipe }})
        </div>
        <div class="chip red" *ngIf="risk === 'high'">
          {{ "case.risk.high" | translate }} ({{ score | scorePipe }})
        </div>
        <div class="chip primary" *ngIf="case.studyParticipation">Study</div>
      </div>
    </div>
    <!--/mat-panel-description-->
  </mat-expansion-panel-header>
  <div class="case-attrs">
    <div class="section">
      <div class="heading">{{ "case.genUID" | translate }}</div>
      <div class="content" data-cy="genUID">
        {{ case.genUID | emptyIndicator }}
      </div>
    </div>
    <div class="section">
      <div class="heading">{{ "case.petID" | translate }}</div>
      <div class="content" data-cy="petID">
        {{ case.petID | emptyIndicator }}
      </div>
    </div>
    <div class="section">
      <div class="heading">{{ "case.updatedAt" | translate }}</div>
      <div class="content">
        {{ case.updatedAt | date: DATE_STRING | emptyIndicator }}
      </div>
    </div>
    <div class="section">
      <div class="heading">{{ "case.createdAt" | translate }}</div>
      <div class="content">
        {{ case.createdAt | date: DATE_STRING | emptyIndicator }}
      </div>
    </div>
    <div class="section">
      <div class="heading">{{ "case.breed" | translate }}</div>
      <div class="content" data-cy="breed">
        {{ case.breed | translate | emptyIndicator }}
      </div>
    </div>
    <div class="section">
      <div class="heading">{{ "case.sex" | translate }}</div>
      <div class="content" data-cy="sex">
        {{ case.sex | sexTranslate | emptyIndicator }}
      </div>
    </div>
  </div>
  <mat-card-actions *ngIf="!case.completed" class="actionbuttons">
    <button mat-raised-button color="warn" (click)="delete()">
      {{ "case.card.delete-btn" | translate | uppercase }}
    </button>
    <button
      data-cy="continueEditBtn"
      (click)="continueEdit()"
      mat-raised-button
    >
      {{ "case.card.continueEditBtn" | translate | uppercase }}
    </button>
  </mat-card-actions>
  <mat-card-actions *ngIf="case.completed" class="actionbuttons right-side">
    <button data-cy="continueEditBtn" (click)="view()" mat-raised-button>
      {{ "case.card.view-case" | translate | uppercase }}
    </button>
    <button mat-raised-button color="primary" (click)="viewScore()">
      {{ "case.card.view-score" | translate | uppercase }}
    </button>
  </mat-card-actions>
</mat-expansion-panel>
