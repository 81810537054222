import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { AccountService } from 'src/app/core/services/account.service';
import { VerifyEmailAction } from 'src/app/core/states/User/verify-email.action';

type VerifyMode = 'resetPassword' | 'verifyEmail';

@Component({
  selector: 'app-email-action',
  templateUrl: './email-action.component.html',
  styleUrls: ['./email-action.component.scss'],
})
export class EmailActionComponent implements OnInit {
  public passwordResetControl = new FormControl();

  public mode: VerifyMode;
  public oobCode: string;

  public error: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private accountService: AccountService
  ) {}

  ngOnInit(): void {
    this.mode = this.activatedRoute.snapshot.queryParams.mode;
    this.oobCode = this.activatedRoute.snapshot.queryParams.oobCode;
    if (this.mode === 'verifyEmail') {
      this.store.dispatch(new VerifyEmailAction(this.oobCode));
    }
  }

  resetPassword(): void {
    this.accountService
      .confirmPasswordReset(
        this.oobCode,
        this.passwordResetControl.value.password
      )
      .then(() => {
        this.accountService.logout();
        this.store.dispatch(new Navigate(['/login']));
      })
      .catch((error) => {
        console.log(error);
        switch (error.code) {
          case 'auth/invalid-action-code':
            this.error = 'email-action.errors.invalid-action-code';
            break;
          default:
            this.error = 'email-action.errors.error';
            break;
        }
      });
  }
}
