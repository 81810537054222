<form [formGroup]="formGroup">
  <mat-form-field>
    <mat-label>{{ "login.password" | translate }}</mat-label>
    <input
      matInput
      formControlName="password"
      data-cy="password"
      type="password"
    />
    <mat-icon matSuffix [class]="formGroup.status">
      {{ formGroup.status === "VALID" ? "check" : "close" }}
    </mat-icon>
    <mat-hint
      class="error"
      *ngIf="formGroup.hasError('minlength', 'password')"
      data-cy="password-rules"
    >
      {{ "register.passwordRules" | translate }}
    </mat-hint>
  </mat-form-field>
  <br />
  <mat-form-field>
    <mat-label>{{ "register.repeat" | translate }}</mat-label>
    <input
      matInput
      formControlName="repeatPassword"
      type="password"
      data-cy="repeat"
    />
    <mat-icon matSuffix [class]="formGroup.status">
      {{ formGroup.status === "VALID" ? "check" : "close" }}
    </mat-icon>
    <mat-hint
      class="error"
      *ngIf="
        !formGroup.get('repeatPassword').untouched &&
        formGroup.hasError('passwordNotMatch')
      "
      data-cy="password-not-match"
    >
      {{ "register.passwordNotMatch" | translate }}
    </mat-hint>
  </mat-form-field>
</form>
