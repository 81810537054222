import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { from, Observable } from 'rxjs';
import firebase from 'firebase';
import auth = firebase.auth;
import { Select, Store } from '@ngxs/store';
import { UserActions } from 'src/app/core/states/User/user.actions';
import { Navigate } from '@ngxs/router-plugin';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  public admin: Observable<boolean>;

  @Input()
  public enableBackButton = false;

  @Input()
  public title = '';

  @Select((state) => state.auth.authenticated)
  public authenticated: Observable<boolean>;

  constructor(
    public translate: TranslateService,
    private store: Store,
    private router: Router,
    private location: Location
  ) {
    if (!!auth().currentUser) {
      this.admin = from(
        auth()
          .currentUser.getIdTokenResult(true)
          .then((tr) => tr.claims.admin === true)
      );
    }
  }

  public async logout() {
    await this.store.dispatch([new UserActions.Logout()]).toPromise();
    await this.store.dispatch([new Navigate(['/'])]).toPromise();
  }

  public async back() {
    await this.store.dispatch([new Navigate(['/'])]).toPromise();
    // this.location.back();
  }
}
