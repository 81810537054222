import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Endpoints } from '../endpoints';
import { map, take } from 'rxjs/operators';
import { EmailNotVerifiedError } from '../errors/EmailNotVerified.error';
import { Store } from '@ngxs/store';
import firebase from 'firebase';
import initializeApp = firebase.initializeApp;
import auth = firebase.auth;
import firestore = firebase.firestore;
import { Vet } from '../models/vet.model';
import { VetActions } from '../states/User/vets.actions';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(private store: Store, private firebaseAuth: AngularFireAuth) {
    if ((window as any).Cypress || environment.firebaseEmulator) {
      // check if Cypress is running and use Emulator instead
      if (!firebase.apps.length) {
        initializeApp(environment.firebase);
      }
      auth(firebase.apps[0]).useEmulator('http://localhost:9099/');
      firebase.firestore().useEmulator('localhost', 8080);
      firebase.firestore().settings({
        experimentalForceLongPolling: true,
        merge: true,
      });
    }
  }

  public async login(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> {
    const userCreds = await this.firebaseAuth.signInWithEmailAndPassword(
      email,
      password
    );
    if (!userCreds.user.emailVerified) {
      throw new EmailNotVerifiedError();
    } else {
      return userCreds;
    }
  }

  public async register(opts: {
    vet: Vet;
    password: string;
    email: string;
  }): Promise<void> {
    await this.firebaseAuth
      .createUserWithEmailAndPassword(opts.email, opts.password)
      .then(async (user: firebase.auth.UserCredential) => {
        opts.vet.uid = user.user.uid;
        await user.user.sendEmailVerification();
        await this.store.dispatch(new VetActions.SetVet(opts.vet)).toPromise();
        return this.logout();
      });
  }

  public async logout(): Promise<void> {
    return this.firebaseAuth.signOut();
  }

  public async verifyEmail(verificationCode: string): Promise<void> {
    return this.firebaseAuth.applyActionCode(verificationCode);
  }

  public async startPasswordReset(email: string): Promise<void> {
    return this.firebaseAuth.sendPasswordResetEmail(email);
  }

  public async confirmPasswordReset(
    token: string,
    password: string
  ): Promise<void> {
    return this.firebaseAuth.confirmPasswordReset(token, password);
  }
}
