<div *ngIf="error">
  {{ error | translate }}
</div>
<div *ngIf="mode === 'resetPassword'">
  <app-password-form [formControl]="passwordResetControl"></app-password-form>
  <button
    [disabled]="
      passwordResetControl.status === 'INVALID' ||
      passwordResetControl.untouched
    "
    mat-raised-button
    color="primary"
    data-cy="reset-password-btn"
    (click)="resetPassword()"
  >
    {{ "email-action.reset-password-btn" | translate }}
  </button>
</div>
