import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseScoreComponent } from './case-score.component';
import { GaugeModule } from 'angular-gauge';
import { MatCardModule } from '@angular/material/card';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  declarations: [CaseScoreComponent],
  imports: [
    CommonModule,
    GaugeModule.forRoot(),
    MatCardModule,
    SharedModule,
    MatDividerModule,
  ],
})
export class CaseScoreModule {}
