<app-content-container
  [enableBackButton]="true"
  [title]="'imprint.title' | translate"
>
  <mat-card #about class="default-card">
    <mat-card-title>
      {{ "imprint.about-card.title" | translate }}
    </mat-card-title>
    <mat-card-content>
      <span [innerHtml]="'imprint.about-card.content' | translate"> </span>
    </mat-card-content>
  </mat-card>
  <mat-card #help class="default-card">
    <mat-card-title>
      {{ "imprint.help-card.title" | translate }}
    </mat-card-title>
    <mat-card-content>
      <span [innerHtml]="'imprint.help-card.content' | translate"></span>
    </mat-card-content>
  </mat-card>
  <mat-card #imprint class="default-card" data-cy="imprint-card">
    <mat-card-title>
      {{ "imprint.imprint-card.title" | translate }}
    </mat-card-title>
    <mat-card-content>
      <span [innerHtml]="'imprint.imprint-card.content' | translate"></span>
    </mat-card-content>
  </mat-card>
  <mat-card #privacy class="default-card" data-cy="privacy-card">
    <mat-card-title>
      {{ "imprint.privacy-card.title" | translate }}
    </mat-card-title>
    <mat-card-content>
      <span [innerHtml]="'imprint.privacy-card.content' | translate"></span>
    </mat-card-content>
  </mat-card>
  <mat-card #copyright class="default-card" data-cy="copyright-card">
    <mat-card-title>
      {{ "imprint.copyright-card.title" | translate }}
    </mat-card-title>
    <mat-card-content>
      <ul>
        <li *ngFor="let number of [1, 2, 3, 4, 5, 6, 7, 8]">
          {{ "imprint.copyright-card.content-" + number | translate }}
        </li>
      </ul>
    </mat-card-content>
  </mat-card>
</app-content-container>
