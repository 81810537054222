import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatCard } from '@angular/material/card';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss'],
})
export class ImprintComponent implements AfterViewInit, OnDestroy {
  @ViewChild('copyright', { read: ElementRef })
  public copyrightCard: ElementRef<MatCard>;

  @ViewChild('privacy', { read: ElementRef })
  public dataPrivacyCard: ElementRef<MatCard>;

  @ViewChild('imprint', { read: ElementRef })
  public imprintCard: ElementRef<MatCard>;

  @ViewChild('about', { read: ElementRef })
  public aboutCard: ElementRef<MatCard>;

  @ViewChild('help', { read: ElementRef })
  public helpCard: ElementRef<MatCard>;

  private destroy$ = new Subject<void>();

  constructor(public activatedRoute: ActivatedRoute) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    console.log('foo');
    this.activatedRoute.fragment
      .pipe(
        takeUntil(this.destroy$),
        filter((fragment) => !!fragment)
      )
      .subscribe((fragment) => {
        console.log('Fragment', fragment);
        // @ts-ignore
        let elem: ElementRef<MatCard> = null;
        switch (fragment) {
          case 'imprint':
            elem = this.imprintCard;
            break;
          case 'data-privacy':
            elem = this.dataPrivacyCard;
            break;
          case 'copyright':
            elem = this.copyrightCard;
            break;
          case 'help':
            elem = this.helpCard;
            break;
          case 'about':
            elem = this.aboutCard;
            break;
          default:
            return;
        }
        // Translate takes some time to load... so we need this timeout
        setTimeout(() => {
          // @ts-ignore scrollIntoView is missing in the type
          elem.nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }, 50);
      });
  }
}
