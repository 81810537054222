{
}
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { take } from 'rxjs/operators';
import { Case } from 'src/app/core/models/case.model';
import { CaseService } from 'src/app/core/services/case.service';
import { CasesState } from 'src/app/core/states/Cases/cases.state';
import { ObservableMap } from '../case-create/observable-map';
import { Warning, WarningType } from '../case-create/warning';

@Component({
  selector: 'app-case-score',
  templateUrl: './case-score.component.html',
  styleUrls: ['./case-score.component.scss'],
})
export class CaseScoreComponent implements OnInit {
  public score = 0;
  public scoreForGauge = 0;

  warnings: ObservableMap<string, Warning> = new ObservableMap<
    string,
    Warning
  >();

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private caseService: CaseService
  ) {}

  public currCase: Case;

  async ngOnInit(): Promise<void> {
    const params = await this.activatedRoute.params.pipe(take(1)).toPromise();
    if (params.uid) {
      this.store.select(CasesState.cases).subscribe((cases) => {
        // Timeout for cool animation
        this.currCase = cases.find((arg) => arg.genUID === params.uid);
        if (this.currCase) {
          this.score = this.caseService.calculateScore(this.currCase) * 100;
          setTimeout(() => {
            this.scoreForGauge = this.score;
          }, 500);
          this.genWarnings();
        }
      });
    }
  }

  genWarnings(): void {
    if (this.currCase.age.years > 18) {
      this.warnings.set(
        'patient.age',
        new Warning(
          'check',
          this.currCase.age.years.toString(),
          undefined,
          undefined,
          WarningType.dismissable
        )
      );
    }
    const hr = this.currCase.physicalExam.heartrate;
    if (hr < 80 || hr > 160) {
      this.warnings.set(
        'physical.heartrate',
        new Warning(
          'check',
          hr.toString(),
          undefined,
          undefined,
          WarningType.dismissable
        )
      );
    }

    const rate = this.currCase.physicalExam.respiratoryRate;
    if (rate < 16 || rate > 52) {
      this.warnings.set(
        'physical.respiratory-rate',
        new Warning(
          'check',
          rate.toString(),
          undefined,
          undefined,
          WarningType.dismissable
        )
      );
    }
    const addWarningComo = (id: string) => {
      this.warnings.set(
        id,
        new Warning(
          'comorbidity',
          '',
          null, // Pass null for the control. The checkboxes won't be focused anyway
          undefined,
          WarningType.info
        )
      );
    };
    const comos = this.currCase.comorbidities;
    if (comos.otherCardiac) {
      addWarningComo('comorbidity.other-cardiac');
    }
    if (comos.chronicKidney) {
      addWarningComo('comorbidity.chronic-kidney');
    }
    if (comos.endocrine) {
      addWarningComo('comorbidity.endocrine');
    }
    if (comos.liver) {
      addWarningComo('comorbidity.liver');
    }
    if (comos.neoplasia) {
      addWarningComo('comorbidity.neoplasia');
    }
    if (comos.other) {
      addWarningComo('comorbidity.other');
    }
    const addWarningMeds = (id: string) => {
      this.warnings.set(
        id,
        new Warning(
          'comorbidity',
          '',
          null, // Pass null for the control. The checkboxes won't be focused anyway
          undefined,
          WarningType.info
        )
      );
    };

    const meds = this.currCase.medication;

    if (meds.pimobendan) {
      addWarningMeds('medication.pimobendan');
    }
    if (meds.endocrine) {
      addWarningMeds('medication.endocrine');
    }
    if (meds.antiArrhythmics) {
      addWarningMeds('medication.anti-arrhythmics');
    }
    if (meds.chemotherapy) {
      addWarningMeds('medication.chemotherapy');
    }
  }

  colorFnGen(): (arg0: number) => string {
    // Because the function is called from a weird place, we need an anonymous function to pass the this
    return (value: number) => {
      return this.caseService.riskColor(value);
    };
  }

  risk(score: number) {
    return this.caseService.risk(score);
  }

  labelFn(value: number) {
    return `${value.toLocaleString(undefined, { maximumFractionDigits: 0 })}%`;
  }
}
