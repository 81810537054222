import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { CoreModule } from 'src/app/core/core.module';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateService } from '@ngx-translate/core';
import { CaseCreateComponent } from './case-create.component';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatStepperModule } from '@angular/material/stepper';
import { RouterModule } from '@angular/router';
import { BcsDialogComponent } from './bcs-dialog/bcs-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MurmurDialogComponent } from './murmur-dialog/murmur-dialog.component';
import { MatTableModule } from '@angular/material/table';
import { InputErrorComponent } from './input-error/input-error.component';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { InputYesNoRadioComponent } from './input-yes-no-radio/input-yes-no-radio.component';

@NgModule({
  declarations: [
    CaseCreateComponent,
    BcsDialogComponent,
    MurmurDialogComponent,
    InputErrorComponent,
    InputYesNoRadioComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatIconModule,
    CoreModule,
    MatButtonModule,
    MatGridListModule,
    MatSelectModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatStepperModule,
    RouterModule,
    MatDialogModule,
    MatListModule,
    MatTableModule,
    MatCardModule,
    MatRadioModule,
  ],
  exports: [CaseCreateComponent],
  providers: [TranslateService],
})
export class CaseCreateModule {}
