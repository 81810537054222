import { ResetPasswordAction } from './../../core/states/User/reset-password.action';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthStateModel } from 'src/app/core/states/User/user.state';
import { ActivatedRoute } from '@angular/router';
import { UserActions } from 'src/app/core/states/User/user.actions';
import { ErrorCodes } from 'src/app/core/states/User/errors.enum';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  @Select((state) => (state.auth as AuthStateModel).errorCode)
  error$: Observable<ErrorCodes>;

  resetPasswordForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  constructor(private store: Store, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.store.dispatch(new UserActions.ResetAuthError());
    this.route.queryParams.subscribe((params) => {
      if (params.email) {
        this.resetPasswordForm.controls.email.setValue(params.email);
      }
    });
  }

  public submit() {
    this.store.dispatch(
      new ResetPasswordAction(this.resetPasswordForm.value.email)
    );
  }
}
