<app-content-container
  [title]="'score.title' | translate: currCase"
  [enableBackButton]="true"
>
  <div class="split-layout small-gap">
    <mat-card class="default-card">
      <mat-card-title>{{
        "score.result-card.title" | translate
      }}</mat-card-title>
      <mwl-gauge
        [max]="100"
        [dialEndAngle]="90 - 40"
        [dialStartAngle]="90 + 40"
        [value]="score"
        [label]="labelFn"
        [color]="colorFnGen()"
        [animated]="true"
        [animationDuration]="1"
      >
      </mwl-gauge>
      <div class="center">
        {{ currCase?.forename }} {{ currCase?.surname }}
        {{ "score.result-card.hasChance" | translate }}
        <b [style.color]="colorFnGen()(score)" class="number">{{
          score | scorePipe
        }}</b>
        {{ "score.result-card.beingIn" | translate }}<br />

        {{
          "score.result-card.thisputs"
            | translate: { forename: currCase?.forename }
        }}
        <b [style.color]="colorFnGen()(score)">{{
          "case.score.group." + risk(score) + ".name" | translate
        }}</b>
      </div>
      <br />
      <div class="center"></div>
    </mat-card>
    <mat-card class="default-card">
      <mat-card-title>{{
        "score.interpretation-card.title" | translate
      }}</mat-card-title>
      <mat-card-content>
        <span
          [innerHTML]="
            'case.score.group.' + risk(score) + '.description'
              | translate: currCase
          "
        >
          | translate: currCase
        </span>
        <br /><br />
        <div *ngIf="risk(score) != 'medium'">
          <mat-divider></mat-divider>
          <br />
          <h3>
            <b
              [innerHTML]="
                'case.score.group.' + risk(score) + '.name'
                  | translate: currCase
              "
            >
              | translate: currCase </b
            ><span
              >, Threshold:
              {{
                "case.score.group." + risk(score) + ".threshold"
                  | translate: currCase
              }}
            </span>
          </h3>
          <span
            [innerHTML]="
              'case.score.group.' + risk(score) + '.explanation'
                | translate: currCase
            "
          ></span>
        </div>
      </mat-card-content>
      <div *ngIf="warnings.size > 0">
        <div
          *ngIf="
            (warnings.observable | async | keyvalue | warningFilterPipe: 1)
              .length > 0
          "
        >
          <h2 class="smaller-h2">
            {{ "score.interpretation-card.disclaimer" | translate }}
          </h2>
          <app-warning-box
            *ngFor="
              let warning of warnings.observable
                | async
                | keyvalue
                | warningFilterPipe: 1
            "
            [warning]="warning"
            [smallWarning]="true"
            [isFormLocked]="true"
          ></app-warning-box>
          <!--app-warning-box
            *ngFor="
              let warning of warnings.observable
                | async
                | keyvalue
                | warningFilterPipe: 2
            "
            [warning]="warning"
            [isFormLocked]="true"
          ></app-warning-box-->
        </div>
      </div>
    </mat-card>
  </div>
  <ng-template #noWarnings>
    <h3>{{ "case.warn.nowarnings" | translate }}</h3>
  </ng-template>
</app-content-container>
