import { Pipe, PipeTransform } from '@angular/core';
import { Case } from 'src/app/core/models/case.model';

@Pipe({ name: 'cardTitlePipe' })
export class CardTitlePipe implements PipeTransform {
  transform(value: Case): string {
    if (value.forename || value.surname) {
      return `${value.forename ?? ''} ${value.surname ?? ''}`;
    }
    return value.genUID;
  }
}
