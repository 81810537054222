<app-content-container [title]="'case-list.title' | translate">
  <div class="pos-abs">
    <div class="centered">
      <mat-card class="search-box">
        <app-welcome-card></app-welcome-card>
        <mat-divider></mat-divider>
        <h2 class="mid-margin-top">Search</h2>
        <div class="inputs">
          <mat-form-field appearance="outline" class="search-bar">
            <mat-label>{{ "case-list.search-bar" | translate }}</mat-label>
            <input matInput type="text" [formControl]="searchForm" />
          </mat-form-field>
          <mat-form-field class="timespan" appearance="outline">
            <mat-label>{{
              "case-list.date-picker.title" | translate
            }}</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
              <input
                data-cy="start-date"
                matStartDate
                formControlName="start"
                placeholder="{{ 'case-list.date-picker.start' | translate }}"
              />
              <input
                data-cy="end-date"
                matEndDate
                formControlName="end"
                placeholder="{{ 'case-list.date-picker.end' | translate }}"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
          <button mat-stroked-button (click)="clearSearch()">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </mat-card>
      <mat-divider></mat-divider>
      <section>
        <h2>
          {{ "case-list.incomplete-cases" | translate }} ({{
            (nonCompletedCases | async).length
          }})
        </h2>
        <mat-accordion>
          <app-case-card
            *ngFor="let case of nonCompletedCases | async | caseSort"
            [case]="case"
          ></app-case-card>
        </mat-accordion>
      </section>
      <!--mat-accordion class="example-headers-align" multi>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header data-cy="incomplete-case-accordion">
        <mat-panel-title>Incomplete Cases</mat-panel-title>
        <mat-panel-description>
          {{ (nonCompletedCases | async).length }}
        </mat-panel-description>
      </mat-expansion-panel-header>

    </mat-expansion-panel>
  </mat-accordion-->
      <mat-divider></mat-divider>
      <section>
        <h2>
          {{ "case-list.complete-cases" | translate }}

          ({{ (completedCases | async).length }})
        </h2>
        <mat-accordion>
          <app-case-card
            *ngFor="let case of completedCases | async | caseSort"
            [case]="case"
          ></app-case-card>
        </mat-accordion>
      </section>
    </div>
  </div>
  <div class="fab-container">
    <button
      mat-fab
      class="fab only-mobile"
      color="primary"
      [routerLink]="['/case-create']"
    >
      <div class="fab-button-inner">
        <mat-icon>add</mat-icon>
      </div>
    </button>
    <button
      mat-raised-button
      class="fab only-not-mobile"
      color="primary"
      [routerLink]="['/case-create']"
    >
      <div class="fab-button-inner">
        <mat-icon>add</mat-icon>
        <p>{{ "case-list.create-new-case" | translate }}</p>
      </div>
    </button>
  </div>
  <div class="bottom-padding"></div>
</app-content-container>
