import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { NgxsModule, Store } from '@ngxs/store';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxsModule,
  ],
  providers: [
    Store
  ],
  exports: [
    NgxsModule
  ]
})
export class CoreModule { }
