import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/firestore';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsFirestoreModule } from '@ngxs-labs/firestore-plugin';
import { NgxsModule } from '@ngxs/store';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CasesState } from './core/states/Cases/cases.state';
import { LanguageState } from './core/states/Language/language.state';
import { UserState } from './core/states/User/user.state';
import { CaseCreateModule } from './pages/case-create/case-create.module';
import { CaseListModule } from './pages/case-list/case-list.module';
import { LoginModule } from './pages/login/login.module';
import { RegisterModule } from './pages/register/register.module';
import { ResetPasswordModule } from './pages/reset-password/reset-password.module';
import { ConfirmResetModule } from './pages/confirm-reset/confirm-reset.module';
import { AdminState } from './core/states/Admin/admin.state';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AdminModule } from './pages/admin/admin.module';
import { CaseScoreModule } from './pages/case-score/case-score.module';
import { EmailActionModule } from './pages/email-action/email-action.module';
import { ImprintModule } from './pages/imprint/imprint.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    ImprintModule,
    CaseScoreModule,
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    BrowserAnimationsModule,
    CaseListModule,
    LoginModule,
    AdminModule,
    RegisterModule,
    ResetPasswordModule,
    CaseCreateModule,
    ConfirmResetModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    HttpClientModule,
    NgxsModule.forRoot([AdminState, LanguageState, UserState, CasesState]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsFirestoreModule.forRoot(),
    NgxsLoggerPluginModule.forRoot(),
    EmailActionModule,
  ],
  providers: [
    {
      provide: SETTINGS,
      useValue: environment.production
        ? undefined
        : {
            host: 'localhost:8080', // https://github.com/angular/angularfire/issues/2183#issuecomment-538402555
            ssl: false,
          },
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {
        displayDefaultIndicatorType: false,
        showError: true,
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
