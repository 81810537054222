{{ title | translate }}
<br />
<mat-radio-group
  [formControl]="formControl"
  class="yes-no-box"
  aria-label="Select an option"
  [attr.data-cy]="dataCy"
>
  <mat-radio-button class="yes" [value]="true">Yes</mat-radio-button>
  <mat-radio-button class="no" [value]="false">No</mat-radio-button>
</mat-radio-group>
<div class="spacer">
  <app-input-error [warnings]="warnings" [name]="warningName"></app-input-error>
</div>
