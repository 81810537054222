<app-content-container
  [title]="'case-create.title' | translate"
  [enableBackButton]="true"
>
  <div class="fab-container"></div>
  <mat-card class="default-card">
    <mat-vertical-stepper>
      <mat-step
        [stepControl]="baseDataForm"
        [label]="'case.meta.step' | translate"
        data-cy="baseDataStep"
        #baseDataStep
      >
        <form [formGroup]="baseDataForm">
          <mat-form-field>
            <mat-label>{{ "case.meta.genUID" | translate }}</mat-label>
            <input
              matInput
              disabled
              data-cy="genUID"
              type="text"
              [value]="genUID"
            />
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>{{ "case.meta.petID" | translate }}</mat-label>
            <input matInput formControlName="petID" data-cy="petID" #petID />
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>{{ "case.meta.forename" | translate }}</mat-label>
            <input
              matInput
              required
              type="text"
              formControlName="forename"
              data-cy="forename"
              #forename
            />
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>{{ "case.meta.surname" | translate }}</mat-label>
            <input
              matInput
              required
              type="text"
              formControlName="surname"
              data-cy="surname"
              #surname
            />
          </mat-form-field>
        </form>
        <br />
        <button mat-stroked-button matStepperNext data-cy="baseDataNext">
          {{ "global.next" | translate }}
        </button>
      </mat-step>

      <mat-step
        [stepControl]="patientInfoForm"
        [label]="'case.patient.step' | translate"
        data-cy="patientInfoStep"
        #patientInfoStep
      >
        <form [formGroup]="patientInfoForm">
          <br />
          <mat-form-field>
            <mat-label>{{ "case.patient.ageYears" | translate }}</mat-label>
            <input
              required
              matInput
              formControlName="ageYears"
              data-cy="ageYears"
              #age
            />
            <mat-hint>
              <app-input-error
                [warnings]="warnings"
                name="patient.age"
              ></app-input-error>
            </mat-hint>
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>{{ "case.patient.ageMonths" | translate }}</mat-label>
            <input
              required
              matInput
              formControlName="ageMonths"
              data-cy="ageMonths"
              #age
            />
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>{{ "case.patient.sex.@" | translate }}</mat-label>
            <mat-select required formControlName="sex" data-cy="sex" #sex>
              <mat-option
                *ngFor="let it of ['me', 'mn', 'fe', 'fn']"
                [value]="it"
              >
                {{ "case.patient.sex." + it | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>{{ "case.patient.breed.@" | translate }}</mat-label>
            <input
              matInput
              type="text"
              required
              formControlName="breed"
              data-cy="breed"
              [matAutocomplete]="breedAuto"
              #breed
            />
            <mat-autocomplete #breedAuto="matAutocomplete">
              <mat-option
                *ngFor="let breed of filteredBreeds | async"
                [value]="'case.patient.breed.' + breed | translate"
              >
                {{ "case.patient.breed." + breed | translate }}
              </mat-option>
            </mat-autocomplete>
            <mat-hint>
              <app-input-error
                [warnings]="warnings"
                name="patient.breed.@"
              ></app-input-error>
            </mat-hint>
          </mat-form-field>
          <br />
          <br />
        </form>
        <br />
        <button mat-stroked-button matStepperNext data-cy="patientInfoNext">
          {{ "global.next" | translate }}
        </button>
      </mat-step>

      <mat-step
        [stepControl]="comorbiditiesForm"
        [label]="'case.comorbidity.step' | translate"
        data-cy="comorbiditiesStep"
        #comorbiditiesStep
      >
        <form [formGroup]="comorbiditiesForm">
          <br />
          <b>{{ "case.comorbidity.title" | translate }}</b
          ><br /><br />
          <app-input-yes-no-radio
            [warnings]="warnings"
            formControlName="comoOtherCardiac"
            title="case.comorbidity.other-cardiac"
            warningName="comorbidity.other-cardiac"
            dataCy="comoOtherCardiac"
          ></app-input-yes-no-radio>
          <app-input-yes-no-radio
            [warnings]="warnings"
            formControlName="comoChronicKidney"
            title="case.comorbidity.chronic-kidney"
            warningName="comorbidity.chronic-kidney"
            dataCy="comoChronicKidney"
          ></app-input-yes-no-radio>
          <app-input-yes-no-radio
            [warnings]="warnings"
            formControlName="comoEndocrine"
            title="case.comorbidity.endocrine"
            warningName="comorbidity.endocrine"
            dataCy="comoEndocrine"
          ></app-input-yes-no-radio>
          <app-input-yes-no-radio
            [warnings]="warnings"
            formControlName="comoLiver"
            title="case.comorbidity.liver"
            warningName="comorbidity.liver"
            dataCy="comoLiver"
          ></app-input-yes-no-radio>
          <app-input-yes-no-radio
            [warnings]="warnings"
            formControlName="comoNeoplasia"
            title="case.comorbidity.neoplasia"
            warningName="comorbidity.neoplasia"
            dataCy="comoNeoplasia"
          ></app-input-yes-no-radio>
          <app-input-yes-no-radio
            [warnings]="warnings"
            formControlName="comoOther"
            title="case.comorbidity.other"
            warningName="comorbidity.other"
            dataCy="comoOther"
          ></app-input-yes-no-radio>
          <mat-form-field>
            <mat-label>{{ "case.comorbidity.details" | translate }}</mat-label>
            <textarea formControlName="comoDetails" matInput></textarea>
          </mat-form-field>
        </form>
        <br />
        <button mat-stroked-button matStepperNext data-cy="comorbiditiesNext">
          {{ "global.next" | translate }}
        </button>
      </mat-step>

      <mat-step
        [stepControl]="medicationForm"
        [label]="'case.medication.step' | translate"
        data-cy="medicationStep"
        #medicationStep
      >
        <form [formGroup]="medicationForm">
          <br />
          <p>
            <b>{{ "case.medication.title" | translate }}</b>
          </p>
          <app-input-yes-no-radio
            [warnings]="warnings"
            formControlName="medsAcei"
            title="case.medication.acei"
            warningName=""
            dataCy="medsAcei"
          ></app-input-yes-no-radio>
          <app-input-yes-no-radio
            [warnings]="warnings"
            formControlName="medsFurosemideTorasemide"
            title="case.medication.furosemide-torasemide"
            warningName="medication.furosemide-torasemide"
            dataCy="medsFurosemideTorasemide"
          ></app-input-yes-no-radio>
          <app-input-yes-no-radio
            [warnings]="warnings"
            formControlName="medsPimobendan"
            title="case.medication.pimobendan"
            warningName="medication.pimobendan"
            dataCy="medsPimobendan"
          ></app-input-yes-no-radio>
          <app-input-yes-no-radio
            [warnings]="warnings"
            formControlName="medsSpironolactone"
            title="case.medication.spironolactone"
            warningName=""
            dataCy="medsSpironolactone"
          ></app-input-yes-no-radio>
          <app-input-yes-no-radio
            [warnings]="warnings"
            formControlName="medsEndocrine"
            title="case.medication.endocrine"
            warningName="medication.endocrine"
            dataCy="medsEndocrine"
          ></app-input-yes-no-radio>
          <app-input-yes-no-radio
            [warnings]="warnings"
            formControlName="medsAntiArrhythmics"
            title="case.medication.anti-arrhythmics"
            warningName="medication.anti-arrhythmics"
            dataCy="medsAntiArrhythmics"
          ></app-input-yes-no-radio>
          <app-input-yes-no-radio
            [warnings]="warnings"
            formControlName="medsChemotherapy"
            title="case.medication.chemotherapy"
            warningName="medication.chemotherapy"
            dataCy="medsChemotherapy"
          ></app-input-yes-no-radio>
          <mat-form-field>
            <mat-label>{{ "case.medication.details" | translate }}</mat-label>
            <textarea matInput formControlName="medsDetails"></textarea>
          </mat-form-field>
        </form>
        <br />
        <button mat-stroked-button matStepperNext data-cy="medicationNext">
          {{ "global.next" | translate }}
        </button>
      </mat-step>

      <mat-step
        [stepControl]="historyForm"
        [label]="'case.history.step' | translate"
        data-cy="historyStep"
        #historyStep
      >
        <form [formGroup]="historyForm">
          <br />
          <p>
            <b>{{ "case.history.title" | translate }}</b>
          </p>
          <app-input-yes-no-radio
            [warnings]="warnings"
            formControlName="histAppetite"
            title="case.history.appetite"
            warningName=""
            dataCy="histAppetite"
          ></app-input-yes-no-radio>
          <app-input-yes-no-radio
            [warnings]="warnings"
            formControlName="histCough"
            title="case.history.cough"
            warningName=""
            dataCy="histCough"
          ></app-input-yes-no-radio>
          <app-input-yes-no-radio
            [warnings]="warnings"
            formControlName="histExerciseTolerance"
            title="case.history.exercise-tolerance"
            warningName=""
            dataCy="histExerciseTolerance"
          ></app-input-yes-no-radio>
        </form>
        <br />
        <button mat-stroked-button matStepperNext data-cy="historyNext">
          {{ "global.next" | translate }}
        </button>
      </mat-step>

      <mat-step
        [stepControl]="physicalExamForm"
        [label]="'case.physical.step' | translate"
        data-cy="physicalExamStep"
        #physicalExamStep
      >
        <form [formGroup]="physicalExamForm">
          <br />
          <div class="form-field-with-info">
            <mat-form-field>
              <mat-label>{{ "case.physical.bcs.@" | translate }}</mat-label>
              <mat-select
                required
                formControlName="physBcs"
                data-cy="physBcs"
                #physBcs
              >
                <mat-option
                  *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9]"
                  value="{{ i }}"
                  >{{ i }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <button
              mat-icon-button
              (click)="openBcsDialog()"
              data-cy="open-bcs-dialog"
            >
              <mat-icon>help_outline</mat-icon>
            </button>
          </div>
          <br />
          <mat-form-field>
            <mat-label>{{ "case.physical.heartrate" | translate }}</mat-label>
            <input
              matInput
              formControlName="physHeartrate"
              data-cy="physHeartrate"
              #physHeartrate
            />
            <mat-hint>
              <app-input-error
                [warnings]="warnings"
                name="physical.heartrate"
              ></app-input-error>
            </mat-hint>
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>{{
              "case.physical.heartrhythm.@" | translate
            }}</mat-label>
            <mat-select
              formControlName="physHeartrhythm"
              data-cy="physHeartrhythm"
              #physHeartrhythm
            >
              <mat-option
                *ngFor="let it of ['regular', 'arrythmia', 'other']"
                [value]="it"
                >{{ "case.physical.heartrhythm." + it | translate }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-form-field
            *ngIf="physicalExamForm.value.physHeartrhythm === 'other'"
          >
            <mat-label>{{
              "case.physical.heartrhythm.other-input" | translate
            }}</mat-label>
            <input
              matInput
              type="text"
              formControlName="physHeartrhythmOther"
            />
          </mat-form-field>
          <br />
          <div class="form-field-with-info">
            <mat-form-field>
              <mat-label>{{ "case.physical.murmur.@" | translate }}</mat-label>
              <mat-select
                required
                formControlName="physMurmur"
                data-cy="physMurmur"
                #physMurmur
              >
                <mat-option value="soft">{{
                  "case.physical.murmur.soft" | translate
                }}</mat-option>
                <mat-option value="moderate">{{
                  "case.physical.murmur.moderate" | translate
                }}</mat-option>
                <mat-option value="loud">{{
                  "case.physical.murmur.loud" | translate
                }}</mat-option>
                <mat-option value="thrilling">{{
                  "case.physical.murmur.thrilling" | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <button
              mat-icon-button
              (click)="openMurmurDialog()"
              data-cy="open-murmur-dialog"
            >
              <mat-icon>help_outline</mat-icon>
            </button>
          </div>
          <br />
          <mat-form-field>
            <mat-label>{{
              "case.physical.respiratory-rate" | translate
            }}</mat-label>
            <input
              required
              matInput
              type="text"
              formControlName="physRespiratoryRate"
              data-cy="physRespiratoryRate"
              #physRespiratoryRate
            />
            <mat-hint>
              <app-input-error
                [warnings]="warnings"
                name="physical.respiratory-rate"
              ></app-input-error>
            </mat-hint>
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>{{ "case.physical.weight" | translate }}</mat-label>
            <input
              required
              matInput
              type="text"
              formControlName="physWeight"
              data-cy="physWeight"
              #physWeight
            />
            <mat-hint>
              <app-input-error
                [warnings]="warnings"
                name="physical.weight"
              ></app-input-error>
            </mat-hint>
          </mat-form-field>
        </form>
        <button
          class="margin-next-btn"
          mat-stroked-button
          matStepperNext
          data-cy="physicalNext"
        >
          {{ "global.next" | translate }}
        </button>
      </mat-step>
      <mat-step
        [stepControl]="bloodTestForm"
        [label]="'case.bloodResults.step' | translate"
        data-cy="bloodStep"
        #bloodExamStep
      >
        <form [formGroup]="bloodTestForm">
          <mat-list>
            <div class="subheader" mat-subheader>
              {{ "global.required" | translate }}
            </div>
            <mat-form-field matRipple matRippleDisabled="true">
              <mat-label>{{ "case.bt.NTproBNP" | translate }}</mat-label>
              <input
                matInput
                formControlName="NTproBNP"
                type="number"
                data-cy="NTproBNP"
                type="number"
                #bloodNTproBNP
              />
              <mat-hint>
                <app-input-error
                  [warnings]="warnings"
                  name="bt.NTproBNP"
                ></app-input-error>
              </mat-hint>
            </mat-form-field>
            <br />
            <mat-form-field matRipple matRippleDisabled="true">
              <mat-label>{{ "case.bt.Creatinine" | translate }}</mat-label>
              <input
                matInput
                formControlName="Creatinine"
                type="number"
                data-cy="Creatinine"
                type="number"
                #bloodCeratine
              />
              <mat-hint>
                <app-input-error
                  [warnings]="warnings"
                  name="bt.Creatinine"
                ></app-input-error>
              </mat-hint>
            </mat-form-field>
            <mat-divider></mat-divider>
            <div class="subheader" mat-subheader>
              {{ "global.avaliable" | translate }}
            </div>
            <mat-form-field>
              <mat-label>{{ "case.bt.cTnl" | translate }}</mat-label>
              <input
                matInput
                formControlName="cTnl"
                type="number"
                data-cy="cTnl"
                type="number"
                #bloodcTnl
              />
              <mat-hint>
                <app-input-error
                  [warnings]="warnings"
                  name="bt.cTnl"
                ></app-input-error>
              </mat-hint>
            </mat-form-field>
            <br />
            <mat-form-field>
              <mat-label>{{ "case.bt.Albumin" | translate }}</mat-label>
              <input
                matInput
                formControlName="Albumin"
                type="number"
                data-cy="Albumin"
                type="number"
                #bloodAlbumin
              />
              <mat-hint>
                <app-input-error
                  [warnings]="warnings"
                  name="bt.Albumin"
                ></app-input-error>
              </mat-hint>
            </mat-form-field>
            <br />
            <mat-form-field>
              <mat-label>{{ "case.bt.ALKP" | translate }}</mat-label>
              <input
                matInput
                formControlName="ALKP"
                type="number"
                data-cy="ALKP"
                type="number"
                #bloodALKP
              />
              <mat-hint>
                <app-input-error
                  [warnings]="warnings"
                  name="bt.ALKP"
                ></app-input-error>
                <br />
                <app-input-error
                  [warnings]="warnings"
                  name="bt.less.ALKP"
                ></app-input-error>
              </mat-hint>
            </mat-form-field>
            <br />
            <mat-form-field>
              <mat-label>{{ "case.bt.ALT" | translate }}</mat-label>
              <input
                matInput
                formControlName="ALT"
                type="number"
                data-cy="ALT"
                type="number"
                #bloodALT
              />
              <mat-hint>
                <app-input-error
                  [warnings]="warnings"
                  name="bt.ALT"
                ></app-input-error>
                <br />
                <app-input-error
                  [warnings]="warnings"
                  name="bt.less.ALT"
                ></app-input-error>
              </mat-hint>
            </mat-form-field>
            <br />
            <mat-form-field>
              <mat-label>{{ "case.bt.Bilirubin" | translate }}</mat-label>
              <input
                matInput
                formControlName="Bilirubin"
                type="number"
                data-cy="Bilirubin"
                type="number"
                #bloodBilirubin
              />
              <mat-hint>
                <app-input-error
                  [warnings]="warnings"
                  name="bt.Bilirubin"
                ></app-input-error>
              </mat-hint>
            </mat-form-field>
            <br />
            <mat-form-field>
              <mat-label>{{ "case.bt.BUN" | translate }}</mat-label>
              <input
                matInput
                formControlName="BUN"
                type="number"
                data-cy="BUN"
                type="number"
                #bloodBUN
              />
              <mat-hint>
                <app-input-error
                  [warnings]="warnings"
                  name="bt.BUN"
                ></app-input-error
              ></mat-hint>
            </mat-form-field>
            <br />
            <mat-form-field>
              <mat-label>{{ "case.bt.Calcium" | translate }}</mat-label>
              <input
                matInput
                formControlName="Calcium"
                type="number"
                data-cy="Calcium"
                type="number"
                #bloodCalcium
              />
              <mat-hint>
                <app-input-error
                  [warnings]="warnings"
                  name="bt.Calcium"
                ></app-input-error>
                <br />
                <app-input-error
                  [warnings]="warnings"
                  name="bt.less.Calcium"
                ></app-input-error
              ></mat-hint>
            </mat-form-field>
            <br />
            <mat-form-field>
              <mat-label>{{ "case.bt.Chloride" | translate }}</mat-label>
              <input
                matInput
                formControlName="Chloride"
                type="number"
                data-cy="Chloride"
                type="number"
                #bloodChloride
              />
              <mat-hint>
                <app-input-error
                  [warnings]="warnings"
                  name="bt.Chloride"
                ></app-input-error
              ></mat-hint>
            </mat-form-field>
            <br />
            <mat-form-field>
              <mat-label>{{ "case.bt.Cholesterol" | translate }}</mat-label>
              <input
                matInput
                formControlName="Cholesterol"
                type="number"
                data-cy="Cholesterol"
                type="number"
                #bloodCholesterol
              />
              <mat-hint>
                <app-input-error
                  [warnings]="warnings"
                  name="bt.Cholesterol"
                ></app-input-error
              ></mat-hint>
            </mat-form-field>
            <br />
            <mat-form-field>
              <mat-label>{{ "case.bt.GGT" | translate }}</mat-label>
              <input
                matInput
                formControlName="GGT"
                type="number"
                data-cy="GGT"
                type="number"
                #bloodGGT
              />
              <mat-hint>
                <app-input-error
                  [warnings]="warnings"
                  name="bt.GGT"
                ></app-input-error
              ></mat-hint>
            </mat-form-field>
            <br />
            <mat-form-field>
              <mat-label>{{ "case.bt.Globulin" | translate }}</mat-label>
              <input
                matInput
                formControlName="Globulin"
                type="number"
                data-cy="Globulin"
                type="number"
                #bloodGlobulin
              />
              <mat-hint>
                <app-input-error
                  [warnings]="warnings"
                  name="bt.Globulin"
                ></app-input-error
              ></mat-hint>
            </mat-form-field>
            <br />
            <mat-form-field>
              <mat-label>{{ "case.bt.Glucose" | translate }}</mat-label>
              <input
                matInput
                formControlName="Glucose"
                type="number"
                data-cy="Glucose"
                type="number"
                #bloodGlucose
              />
              <mat-hint>
                <app-input-error
                  [warnings]="warnings"
                  name="bt.Glucose"
                ></app-input-error>
                <br />
                <app-input-error
                  [warnings]="warnings"
                  name="bt.less.Glucose"
                ></app-input-error>
              </mat-hint>
            </mat-form-field>
            <br />
            <mat-form-field>
              <mat-label>{{ "case.bt.Phosphate" | translate }}</mat-label>
              <input
                matInput
                formControlName="Phosphate"
                type="number"
                data-cy="Phosphate"
                type="number"
                #bloodPhosphate
              />
              <mat-hint>
                <app-input-error
                  [warnings]="warnings"
                  name="bt.Phosphate"
                ></app-input-error
              ></mat-hint>
            </mat-form-field>
            <br />
            <mat-form-field>
              <mat-label>{{ "case.bt.Potassium" | translate }}</mat-label>
              <input
                matInput
                formControlName="Potassium"
                type="number"
                data-cy="Potassium"
                type="number"
                #bloodPotassium
              />
              <mat-hint>
                <app-input-error
                  [warnings]="warnings"
                  name="bt.Potassium"
                ></app-input-error
              ></mat-hint>
            </mat-form-field>
            <br />
            <mat-form-field>
              <mat-label>{{ "case.bt.SDMA" | translate }}</mat-label>
              <input
                matInput
                formControlName="SDMA"
                type="number"
                data-cy="SDMA"
                type="number"
                #bloodSDMA
              />
              <mat-hint>
                <app-input-error
                  [warnings]="warnings"
                  name="bt.SDMA"
                ></app-input-error
              ></mat-hint>
            </mat-form-field>
            <br />
            <mat-form-field>
              <mat-label>{{ "case.bt.Sodium" | translate }}</mat-label>
              <input
                matInput
                formControlName="Sodium"
                type="number"
                data-cy="Sodium"
                type="number"
                #bloodSodium
              />
              <mat-hint>
                <app-input-error
                  [warnings]="warnings"
                  name="bt.Sodium"
                ></app-input-error
              ></mat-hint>
            </mat-form-field>
            <br />
            <button
              class="margin-next-btn"
              mat-stroked-button
              matStepperNext
              data-cy="bloodNext"
            >
              {{ "global.next" | translate }}
            </button>
          </mat-list>
        </form>
      </mat-step>
      <mat-step
        [stepControl]="imageTestForm"
        [label]="'case.image-test-results.step' | translate"
        data-cy="imageStep"
        #imageStep
      >
        <form [formGroup]="imageTestForm">
          <mat-form-field>
            <mat-label>{{
              "case.image-test-results.VHS" | translate
            }}</mat-label>
            <input
              matInput
              formControlName="VHS"
              type="number"
              data-cy="VHS"
              #imageVHS
            />
            <mat-hint>
              <app-input-error
                [warnings]="warnings"
                name="image-test-results.VHS"
              ></app-input-error
            ></mat-hint>
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>{{
              "case.image-test-results.VLAS" | translate
            }}</mat-label>
            <input
              matInput
              formControlName="VLAS"
              type="number"
              data-cy="VLAS"
              #imageVLAS
            />
            <mat-hint>
              <app-input-error
                [warnings]="warnings"
                name="image-test-results.VLAS"
              ></app-input-error
            ></mat-hint>
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>{{
              "case.image-test-results.LAAo" | translate
            }}</mat-label>
            <input
              matInput
              formControlName="LAAo"
              type="number"
              data-cy="LAAo"
              #imageLAAo
            />
            <mat-hint>
              <app-input-error
                [warnings]="warnings"
                name="image-test-results.LAAo"
              ></app-input-error
            ></mat-hint>
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>{{
              "case.image-test-results.LVIDDN" | translate
            }}</mat-label>
            <input
              matInput
              formControlName="LVIDDN"
              type="number"
              data-cy="LVIDDN"
              #imageLVIDDN
            />
            <mat-hint>
              <app-input-error
                [warnings]="warnings"
                name="image-test-results.LVIDDN"
              ></app-input-error
            ></mat-hint>
          </mat-form-field>
        </form>
        <button mat-stroked-button matStepperNext>
          {{ "global.next" | translate }}
        </button>
      </mat-step>
    </mat-vertical-stepper>
  </mat-card>
  <br />
  <mat-card class="default-card warning-card">
    <div *ngIf="warnings.size > 0; else noWarnings">
      <h2>{{ "case.warn.title-actions" | translate }}</h2>
      <app-warning-box
        *ngFor="
          let warning of warnings.observable
            | async
            | keyvalue
            | warningActionFilterPipe
            | warningFilterPipe: 0
        "
        [warning]="warning"
        [isFormLocked]="isFormLocked"
      ></app-warning-box>
      <app-warning-box
        *ngFor="
          let warning of warnings.observable
            | async
            | keyvalue
            | warningActionFilterPipe
            | warningFilterPipe: 2
        "
        [warning]="warning"
        [isFormLocked]="isFormLocked"
      ></app-warning-box>
    </div>
    <ng-template #noWarnings>
      <h3>{{ "case.warn.nowarnings" | translate }}</h3>
    </ng-template>
  </mat-card>
  <div class="btn-row default-card" *ngIf="!isFormLocked">
    <button
      mat-raised-button
      color="accent"
      (click)="createCase()"
      data-cy="save"
      class="left-margin"
    >
      {{ "case.saveBtnTitle" | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="complete()"
      [disabled]="lockSubmit"
      data-cy="submit"
    >
      {{ "case.completeBtnTitle" | translate }}
    </button>
  </div>
  <button
    mat-raised-button
    color="primary"
    (click)="viewScore()"
    *ngIf="isFormLocked"
    class="view-score-btn"
  >
    {{ "case.card.view-score" | translate }}
  </button>
</app-content-container>
