<div class="single-problem" [class.small]="smallWarning">
  <div
    [attr.data-cy]="'warn-' + warning.key"
    [class]="warning.value.class | async"
    class="problem-header"
    *ngIf="warning.key === 'warn.required'"
  >
    <mat-icon>{{ warning.value.icon | async }}</mat-icon>
    {{ "case." + warning.key | translate }}
  </div>
  <div
    class="problem-header"
    [attr.data-cy]="'warn-' + warning.key"
    [class]="warning.value.class | async"
    *ngIf="warning.key !== 'warn.required'"
  >
    <mat-icon>{{ warning.value.icon | async }}</mat-icon>
    <a
      href="javascript:void(0)"
      (click)="warning.value.goto()"
      class="goto"
      *ngIf="!smallWarning"
    >
      {{ "case." + warning.key | translate }}
    </a>
    <div *ngIf="smallWarning" class="goto">
      {{ "case." + warning.key | translate }}
    </div>
    <div [class]="warning.value.class | async">
      {{ warning.value.value }}
    </div>
  </div>
  {{ "case.warn.warnings." + warning.value.warning | translate }}
  <button
    mat-flat-button
    color="accent"
    *ngIf="
      warning.value.dismissable() &&
      !warning.value.dismissed.value &&
      !isFormLocked
    "
    (click)="warning.value.dismissed.next(true)"
    [attr.data-cy]="'warn-resolve-' + warning.key"
    class="resolve-btn"
  >
    {{ "case.warn.resolve" | translate }}
  </button>
  <div class="buffer-box"></div>
  <mat-divider></mat-divider>
</div>
