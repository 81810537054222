export namespace UserActions {
  export class Logout {
    static readonly type = '[User] Logout';
  }

  export class LoginUser {
    static readonly type = '[User] login';

    constructor(public email: string, public password: string) {}
  }

  export class LoadUser {
    static readonly type = '[User] loadUser';
  }

  export class ResetAuthError {
    static readonly type = '[User] ResetAuthError';
  }
}
