import { ConfirmResetAction } from './confirm-reset.action';
import { ResetPasswordAction } from './reset-password.action';
import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store, Selector } from '@ngxs/store';
import { AccountService } from '../../services/account.service';
import { LoginUser } from './login-user.action';
import { Navigate } from '@ngxs/router-plugin';
import { RegisterUser } from './register-user.action';
import { VerifyEmailAction } from './verify-email.action';
import { ErrorCodes } from './errors.enum';
import { StartEmailVerificationAction } from './start-email-verification.action';
import { LoginState } from './loginState.enum';
import firebase from 'firebase';
import auth = firebase.auth;
import { Vet } from '../../models/vet.model';
import {
  Emitted,
  NgxsFirestoreConnect,
  StreamEmitted,
} from '@ngxs-labs/firestore-plugin';
import { VetsFirestore } from './vets.firestore';
import { VetActions } from './vets.actions';
import { CasesActions } from '../Cases/cases.actions';
import { UserActions } from './user.actions';
import { TranslateService } from '@ngx-translate/core';

export interface AuthStateModel {
  user: firebase.User;
  authenticated: boolean;
  errorCode: ErrorCodes;
  loginState: LoginState;
  vet: Vet;
  infoTextLogin: string;
  registrationInfoText: string;
}

const defaults = {
  authenticated: false,
  user: null,
  errorCode: null,
  vet: null,
  infoTextLogin: null,
  loginState: LoginState.unauthenticated,
  registrationInfoText: null,
};

@State<AuthStateModel>({
  name: 'auth',
  defaults,
})
@Injectable()
export class UserState {
  constructor(
    private accountService: AccountService,
    private store: Store,
    private ngxsFirestoreConnect: NgxsFirestoreConnect,
    private vetsFs: VetsFirestore,
    private ts: TranslateService
  ) {}

  @Selector()
  public static user(state: AuthStateModel) {
    return state.user;
  }

  @Selector()
  public static loginInfoText(state: AuthStateModel) {
    return state.infoTextLogin;
  }

  ngxsOnInit() {
    firebase.auth().onAuthStateChanged((currUser) => {
      if (currUser !== null) {
        this.store.dispatch(new UserActions.LoadUser());
      }
    });

    this.ngxsFirestoreConnect.connect(VetActions.GetVet, {
      to: (action: VetActions.GetVet) => {
        console.log('Getting vet');
        return this.vetsFs.doc$(auth().currentUser.uid);
      },
    });
    this.ngxsFirestoreConnect.connect(VetActions.SetVet, {
      to: (setVet: VetActions.SetVet) =>
        this.vetsFs.update$(setVet.vet.uid, setVet.vet),
    });

    this.ngxsFirestoreConnect.connect(VetActions.ChangeParticipation, {
      trackBy: (changeParticipationAction: VetActions.ChangeParticipation) =>
        changeParticipationAction.id,
      to: (changeParticipationAction: VetActions.ChangeParticipation) =>
        this.vetsFs.update$(
          changeParticipationAction.id,
          {
            studyParticipation: changeParticipationAction.takesPart,
          },
          { merge: true }
        ),
    });

    this.ngxsFirestoreConnect.connect(VetActions.SetLang, {
      to: (setLangAction: VetActions.SetLang) =>
        this.vetsFs.update$(
          setLangAction.userId,
          {
            language: setLangAction.lang,
          },
          { merge: true }
        ),
    });
  }

  @Action(UserActions.LoadUser)
  public async loadUser(ctx: StateContext<AuthStateModel>, action: LoginUser) {
    ctx.patchState({
      authenticated: true,
      user: firebase.auth().currentUser,
    });
    firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        ctx.dispatch([new VetActions.GetVet()]);
        this.store.dispatch(new CasesActions.GetAll());
        this.store.dispatch(
          new VetActions.SetLang(auth().currentUser.uid, navigator.language)
        );
      });
  }

  @Action(StreamEmitted(VetActions.GetVet))
  get(
    ctx: StateContext<AuthStateModel>,
    { payload }: Emitted<VetActions.GetVet, Vet>
  ) {
    ctx.patchState({ vet: payload });
  }

  @Action(UserActions.LoginUser)
  public async loginUser(ctx: StateContext<AuthStateModel>, action: LoginUser) {
    return this.accountService
      .login(action.email, action.password)
      .then((userCredential: firebase.auth.UserCredential) => {
        if (!userCredential.user.emailVerified) {
          ctx.patchState({
            errorCode: ErrorCodes.notVerifiedEmail,
            infoTextLogin: this.ts.instant('login.notVerifiedEmail'),
          });
        }
        ctx.patchState({
          authenticated: true,
          user: userCredential.user,
        });
        userCredential.user.getIdTokenResult().then((idTokenResult) => {
          this.store.dispatch(new CasesActions.GetAll());
          this.store.dispatch(new Navigate(['/case-list']));
        });
      })
      .catch(() => {
        ctx.patchState({
          errorCode: ErrorCodes.genericError, // Todo: Better error handeling
          infoTextLogin: this.ts.instant('login.badLoginCreds'),
        });
        ctx.dispatch(new UserActions.Logout());
      });
  }

  @Action(UserActions.Logout)
  public async logoutUser(
    ctx: StateContext<AuthStateModel>,
    action: UserActions.Logout
  ) {
    await this.accountService.logout();
    ctx.patchState({
      authenticated: false,
      loginState: LoginState.unauthenticated,
      user: null,
      vet: null,
    });
  }

  @Action(UserActions.ResetAuthError)
  public async resetAuthError({ patchState }: StateContext<AuthStateModel>) {
    patchState({ errorCode: null });
  }

  @Action(RegisterUser)
  public async registerUser(
    ctx: StateContext<AuthStateModel>,
    action: RegisterUser
  ) {
    return this.accountService
      .register(action.opts)
      .then(() => {
        ctx.patchState({
          loginState: LoginState.registrationSuccessful,
          errorCode: null,
        });
        ctx.dispatch([
          new Navigate(['/login']),
          new StartEmailVerificationAction(),
        ]);
      })
      .catch((exp) => {
        if (exp.code === 'auth/email-already-in-use') {
          ctx.patchState({
            registrationInfoText: this.ts.instant(
              'register.error.email-exists'
            ),
          });
        }
      });
  }

  @Action(StartEmailVerificationAction)
  public async startEmailVerification(
    ctx: StateContext<AuthStateModel>,
    action: StartEmailVerificationAction
  ) {
    ctx.patchState({
      infoTextLogin: this.ts.instant('login.startVerifyEmail'),
    });
  }

  @Action(VerifyEmailAction)
  public async verifyEmail(
    ctx: StateContext<AuthStateModel>,
    action: VerifyEmailAction
  ) {
    return this.accountService
      .verifyEmail(action.emailCode)
      .then(() => {
        ctx.patchState({
          loginState: LoginState.emailVerified,
          errorCode: null,
        });
        this.store.dispatch(new Navigate(['/login']));
      })
      .catch((error: auth.Error) => {
        ctx.patchState({
          errorCode: error.code as ErrorCodes,
        });
      });
  }
  @Action(ResetPasswordAction)
  public async resetPassword(
    ctx: StateContext<AuthStateModel>,
    action: ResetPasswordAction
  ) {
    this.accountService
      .startPasswordReset(action.email)
      .then(() => {
        ctx.patchState({
          infoTextLogin: this.ts.instant('login.reset-password-start'),
        });
        this.store.dispatch(new Navigate(['/login']));
      })
      .catch((error: auth.Error) => {
        ctx.patchState({
          errorCode: error.code as ErrorCodes,
        });
      });
  }
}
