import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'sexTranslate' })
export class SexTranslatePipe implements PipeTransform {
  constructor(private readonly translate: TranslateService) {}

  transform(value: 'me' | 'mn' | 'fe' | 'fn'): string {
    if (value === 'me' || value === 'mn' || value === 'fe' || value === 'fn') {
      return this.translate.instant(`case.patient.sex.${value}`);
    } else {
      return '';
    }
  }
}
