import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscribable,
  Subscriber,
  Subscription,
  Unsubscribable,
} from 'rxjs';

export class ObservableMap<K, V extends Subscribable<V>> extends Map<K, V> {
  private subscriptions: Map<K, Unsubscribable> = new Map<K, Unsubscribable>();

  public observable: Subject<ObservableMap<K, V>> = new BehaviorSubject<
    ObservableMap<K, V>
  >(this);

  private dispatchUpdate(): void {
    this.observable.next(this);
  }

  public clear(): void {
    super.clear();
    this.dispatchUpdate();
  }

  public delete(key: K): boolean {
    if (super.delete(key)) {
      this.subscriptions.get(key).unsubscribe();
      this.subscriptions.delete(key);
      this.dispatchUpdate();
      return true;
    }
    return false;
  }

  public set(key: K, value: V): this {
    if (this.subscriptions.has(key)) {
      this.subscriptions.get(key).unsubscribe();
    }
    super.set(key, value);
    this.subscriptions.set(
      key,
      value.subscribe(() => {
        this.dispatchUpdate();
      })
    );
    this.dispatchUpdate();
    return this;
  }
}
