<mat-toolbar>
  <div class="align-center">
    <p>
      <a>
        <img
          data-cy="footer-logo"
          class="logo"
          src="assets/rvc_logo.png"
          [alt]="'alt.rvc_logo' | translate"
        />
      </a>
    </p>
  </div>
  <div class="not-on-mobile">
    <div class="align-center">
      <p class="center not-on-mobile">
        <b>{{ "footer.about.title" | translate }}</b
        ><br />
        <span class="about-text">
          {{ "footer.about.content-first-line" | translate }}
          <br />{{ "footer.about.content-second-line" | translate }}
          <a data-cy="doi-link" href="https://doi.org/10.1111/jvim.16083"
            >https://doi.org/10.1111/jvim.16083</a
          >
        </span>
      </p>
    </div>
  </div>
  <div class="align-center">
    <div class="doi">
      <a
        class="on-mobile"
        [routerLink]="['/information']"
        fragment="about"
        data-cy="footer-help-link"
        >{{ "footer.links.about" | translate }}</a
      ><br />
      <a
        [routerLink]="['/information']"
        fragment="help"
        data-cy="footer-help-link"
        >{{ "footer.links.help" | translate }}</a
      ><br />
      <a
        [routerLink]="['/information']"
        fragment="copyright"
        data-cy="footer-copyright-link"
        >{{ "footer.links.copyright" | translate }}</a
      ><br />
      <a
        [routerLink]="['/information']"
        fragment="data-privacy"
        data-cy="footer-privacy-link"
        >{{ "footer.links.privacy" | translate }}</a
      ><br />
      <a
        [routerLink]="['/information']"
        fragment="imprint"
        data-cy="footer-imprint-link"
        >{{ "footer.links.imprint" | translate }}</a
      ><br />
    </div>
  </div>
</mat-toolbar>
