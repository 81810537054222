import { Injectable } from '@angular/core';
import {
  Action,
  State,
  StateContext,
  Store,
  Selector,
  NgxsOnInit,
} from '@ngxs/store';
import { AccountService } from '../../services/account.service';
import { GenerateVerificationToken } from './generate-verification-token.action';
import { AdminFirestore } from './admin.firestore';
import {
  NgxsFirestoreConnect,
  StreamEmitted,
  Emitted,
} from '@ngxs-labs/firestore-plugin';

export interface VerificationToken {
  token: string;
  id?: string;
}

export interface AdminStateModel {
  verificationTokens: VerificationToken[];
}

export class GetAll {
  public static readonly type = '[Admin] GetAll';
}

export class Get {
  public static readonly type = '[Admin] Get';
  constructor(public payload: string) {}
}

@State<AdminStateModel>({
  name: 'admin',
  defaults: {
    verificationTokens: [],
  },
})
@Injectable()
export class AdminState implements NgxsOnInit {
  constructor(
    private accountService: AccountService,
    private adminFS: AdminFirestore,
    private ngxsFirestoreConnect: NgxsFirestoreConnect
  ) {}

  ngxsOnInit() {
    this.ngxsFirestoreConnect.connect(GetAll, {
      to: () => this.adminFS.collection$(),
    });
  }
}
