<h1 mat-dialog-title>{{ "case.physical.murmur.@" | translate }}</h1>
<div mat-dialog-content>
  <table mat-table [dataSource]="['soft', 'moderate', 'loud', 'thrilling']">
    <ng-container matColumnDef="intensity">
      <th mat-header-cell *matHeaderCellDef>
        {{ "case.physical.murmur.@" | translate }}
      </th>
      <td mat-cell *matCellDef="let intensity">
        {{ "case.physical.murmur." + intensity | translate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>
        {{ "case.physical.murmur.description.@" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let intensity"
        [attr.data-cy]="'murmur-description-' + intensity"
      >
        {{ "case.physical.murmur.description." + intensity | translate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="levine">
      <th mat-header-cell *matHeaderCellDef>
        {{ "case.physical.murmur.levine-grade.@" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let intensity"
        [attr.data-cy]="'murmur-levine-grade.' + intensity"
      >
        {{ "case.physical.murmur.levine-grade." + intensity | translate }}
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="['intensity', 'description', 'levine']"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['intensity', 'description', 'levine']"
    ></tr>
  </table>
  <br />
  <span class="source">
    <p>{{ "case.source.title" | translate }}:</p>
    <p>
      {{ "case.source.content-murmur" | translate }}
      <br />
      <a
        href="https://onlinelibrarystatic.wiley.com/store/10.1111/jsap.12265/asset/jsap12265.pdf?v=1&t=j626s7z8&s=ee9679e32794dce4b017eedeaf0ff0b13e115d5c"
      >
        https://onlinelibrarystatic.wiley.com/store/10.1111/jsap.12265/asset/jsap12265.pdf?v=1&amp;t=j626s7z8&amp;s=ee9679e32794dce4b017eedeaf0ff0b13e115d5c
      </a>
    </p>
    <p>
      {{ "case.source.content-murmur-2" | translate }}
      <br />
      <a
        href="http://jama.jamanetwork.com/article.aspx?doi=10.1001/jama.1961.73040300014012"
      >
        http://jama.jamanetwork.com/article.aspx?doi=10.1001/jama.1961.73040300014012
      </a>
    </p>
  </span>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    (click)="this.dialogRef.close()"
    data-cy="close-murmur-dialog"
  >
    {{ "global.close" | translate }}
  </button>
</div>
