import { State, StateContext, Action, NgxsOnInit } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { L18nService } from '../../services/l18n.service';
import { ChangeLanguage } from './change-language.action';

export interface LanguageStateModel {
  language: string;
}

@State<LanguageStateModel>({
  name: 'language',
  defaults: {
    language: navigator.language.substr(0, 2),
  },
})
@Injectable()
export class LanguageState implements NgxsOnInit {
  constructor(private l18nService: L18nService) {}

  public ngxsOnInit(ctx: StateContext<LanguageStateModel>) {
    this.l18nService.changeLanguage(ctx.getState().language);
  }

  @Action(ChangeLanguage)
  public changeLanguage(
    ctx: StateContext<LanguageStateModel>,
    action: ChangeLanguage
  ) {
    this.l18nService.changeLanguage(action.newLanguage);
    ctx.patchState({
      language: action.newLanguage,
    });
  }
}
