import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './murmur-dialog.component.html',
  styleUrls: ['../dialogs.scss'],
})
export class MurmurDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<MurmurDialogComponent>) {}

  ngOnInit(): void {}
}
