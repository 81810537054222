import { KeyValue } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Warning } from 'src/app/pages/case-create/warning';

@Pipe({ name: 'warningSortPipe' })
export class WarningSortPipe implements PipeTransform {
  transform(
    warnings: KeyValue<string, Warning>[]
  ): KeyValue<string, Warning>[] {
    return warnings.sort((a, b) => {
      return a.value.severity() - b.value.severity();
    });
  }
}
