import { ConfirmResetAction } from './../../core/states/User/confirm-reset.action';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MatchValuesValidator } from 'src/app/shared/validators/match.validator';
import { Select, Store } from '@ngxs/store';
import { ActivatedRoute } from '@angular/router';
import { AuthStateModel } from 'src/app/core/states/User/user.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-confirm-reset',
  templateUrl: './confirm-reset.component.html',
  styleUrls: ['./confirm-reset.component.scss'],
})
export class ConfirmResetComponent implements OnInit {
  @Select((state) => (state.auth as AuthStateModel).errorCode)
  error$: Observable<string>;

  confirmResetForm = new FormGroup({
    token: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]), // TODO: Add a validator for enforcing a password-policy
    passwordRepeat: new FormControl(''), // Validators added in constructor
  });
  hideTokenInput = false;

  constructor(private store: Store, private route: ActivatedRoute) {
    // Add validators for passwordRepeat control
    this.confirmResetForm.controls.passwordRepeat.setValidators([
      Validators.required,
      MatchValuesValidator(this.confirmResetForm.controls.password),
    ]);
  }

  ngOnInit(): void {
    // Re-evaluate validity of repeat control when password control is changed
    this.confirmResetForm.controls.password.valueChanges.subscribe(() => {
      this.confirmResetForm.controls.passwordRepeat.updateValueAndValidity();
    });

    // Auto-fill verification token (oobCode)
    this.route.queryParams.subscribe((params) => {
      if (params.oobCode) {
        this.confirmResetForm.controls.token.setValue(params.oobCode);
        this.hideTokenInput = true;
      }
    });
  }

  public submit() {
    this.store.dispatch(
      new ConfirmResetAction(
        this.confirmResetForm.value.token,
        this.confirmResetForm.value.password
      )
    );
  }
}
