import { Component, Input, OnInit } from '@angular/core';
import { Warning } from '../warning';

@Component({
  selector: 'app-input-error',
  templateUrl: './input-error.component.html',
  styleUrls: ['../case-create.component.scss'],
})
export class InputErrorComponent implements OnInit {
  @Input()
  warnings: Map<string, Warning>;

  @Input()
  name: string;

  constructor() {}

  ngOnInit(): void {}
}
