<!-- TODO: make null safe-->
<app-content-container [enableBackButton]="true">
  <mat-card class="default-card">
    <mat-card-title>{{ "admin.add-card.title" | translate }}</mat-card-title>
    <mat-card-content class="input-add-card">
      {{ "admin.add-card.input-field-desc" | translate }}
      <mat-form-field appearance="fill">
        <mat-label>{{
          "admin.add-card.input-field-label" | translate
        }}</mat-label>
        <textarea
          matInput
          data-cy="bulk-input"
          [formControl]="inputFormControl"
        ></textarea>
      </mat-form-field>
      <button
        (click)="addToStudyBtn()"
        data-cy="add-to-study-btn"
        mat-raised-button
      >
        {{ "admin.add-card.input-field-btn" | translate }}
      </button>
    </mat-card-content>
  </mat-card>
  <mat-card class="default-card list-card">
    <mat-card-title>{{ "admin.editUsers" | translate }}</mat-card-title>
    <mat-card-content>
      <mat-list class="edit-users-list">
        <table mat-table [dataSource]="userData">
          <ng-container matColumnDef="uid">
            <th mat-header-cell *matHeaderCellDef>
              {{ "admin.uid" | translate }}
            </th>
            <td
              mat-cell
              [attr.data-cy]="user.email + '-uid'"
              *matCellDef="let user"
            >
              {{ user.uid }}
            </td>
          </ng-container>
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>
              {{ "login.email" | translate }}
            </th>
            <td
              mat-cell
              [attr.data-cy]="user.email + '-email'"
              *matCellDef="let user"
            >
              {{ user.email }}
            </td>
          </ng-container>
          <ng-container matColumnDef="displayName">
            <th mat-header-cell *matHeaderCellDef>
              {{ "register.name" | translate }}
            </th>
            <td mat-cell *matCellDef="let user">{{ user.name }}</td>
          </ng-container>
          <ng-container matColumnDef="admin">
            <th mat-header-cell *matHeaderCellDef>
              {{ "toolbar.admin" | translate }}
            </th>
            <td mat-cell *matCellDef="let user">
              <mat-checkbox
                [attr.data-cy]="user.email + '-admin-toggle'"
                (click)="changeAdminState(user.uid, !user.admin)"
                [checked]="user.admin"
                [disabled]="user.uid === (firebaseUser | async).uid"
              ></mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="partInStudy">
            <th mat-header-cell *matHeaderCellDef>
              {{ "admin.participation" | translate }}
            </th>
            <td mat-cell *matCellDef="let user">
              <mat-checkbox
                [attr.data-cy]="user.email + '-participation-toggle'"
                (click)="
                  changeStudyParticipationStatus(
                    user.uid,
                    !user.studyParticipation
                  )
                "
                [checked]="user.studyParticipation"
              ></mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="creationTime">
            <th mat-header-cell *matHeaderCellDef>
              {{ "admin.creationTime" | translate }}
            </th>
            <td mat-cell *matCellDef="let user">
              {{ user.creationTime }}
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="[
              'uid',
              'email',
              'displayName',
              'admin',
              'partInStudy',
              'creationTime'
            ]"
          ></tr>
          <tr
            mat-row
            *matRowDef="
              let row;
              columns: [
                'uid',
                'email',
                'displayName',
                'admin',
                'partInStudy',
                'creationTime'
              ]
            "
          ></tr>
        </table>
      </mat-list>
    </mat-card-content>
  </mat-card>
  <mat-card class="default-card">
    <mat-card-title>{{
      "admin.cases-export-title" | translate
    }}</mat-card-title>
    <mat-card-content>
      <p class="cases-open" data-cy="stage1Text">
        {{ "admin.stageOneP1" | translate }} {{ openCasesNum | async }}
        {{ "admin.stageOneP2" | translate }}
      </p>
      <p>
        {{
          "admin.stageOneTwo"
            | translate
              : {
                  completed: (completedCasesNum | async),
                  study: (completedCasesStudyNum | async)
                }
        }}
      </p>
      <div class="export-btn-list">
        <button
          data-cy="exportCSVButtonAll"
          mat-raised-button
          (click)="exportUsers()"
        >
          {{ "admin.exportCSV-users" | translate }}
        </button>
        <button
          data-cy="exportCSVButtonAll"
          mat-raised-button
          (click)="exportAllCases()"
        >
          {{ "admin.exportCSV-all" | translate }}
        </button>
        <button
          data-cy="exportCSVButtonIncomplete"
          mat-raised-button
          (click)="exportIncompleteCases()"
        >
          {{ "admin.exportCSV-incomplete" | translate }}
        </button>
        <button
          data-cy="exportCSVButtonComplete"
          mat-raised-button
          (click)="exportCompleteCases()"
        >
          {{ "admin.exportCSV-complete" | translate }}
        </button>
        <button
          data-cy="exportCSVButtonCompleteStudy"
          mat-raised-button
          (click)="exportCompleteStudyCases()"
        >
          {{ "admin.exportCSV-complete-study" | translate }}
        </button>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-divider></mat-divider>
</app-content-container>
