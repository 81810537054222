import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Case } from 'src/app/core/models/case.model';
import { CaseService } from 'src/app/core/services/case.service';
import { CasesActions } from 'src/app/core/states/Cases/cases.actions';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-case-card',
  templateUrl: './case-card.component.html',
  styleUrls: ['./case-card.component.scss'],
})
export class CaseCardComponent implements OnInit, OnDestroy {
  @Input()
  public case: Case;
  @Input()
  public buttons?: boolean;
  @Input()
  public detailed?: boolean;

  public score: number;
  public DATE_STRING = 'd.M.YYYY HH:mm';

  public lowThreshhold = 0.2;
  public highThreshold = 0.7;

  private destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private caseService: CaseService,
    private dialog: MatDialog,
    private ts: TranslateService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get risk() {
    return this.caseService.risk(this.score);
  }

  ngOnInit(): void {
    this.score = this.caseService.calculateScore(this.case) * 100;
  }

  public continueEdit() {
    this.store.dispatch(new Navigate([`/case-create/${this.case.genUID}`]));
  }

  public delete() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleText: this.ts.instant('case-list.delete-btn.title'),
        descText: this.ts.instant('case-list.delete-btn.desc'),
        confirmText: this.ts.instant('case-list.delete-btn.confirm-btn'),
        abortText: this.ts.instant('case-list.delete-btn.abort-btn'),
      },
    });
    dialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.destroy$),
        filter((a) => a) // result is truthy
      )
      .subscribe(() =>
        this.store.dispatch(new CasesActions.DeleteCase(this.case))
      );
  }

  public view() {
    this.store.dispatch(new Navigate([`/case/${this.case.genUID}`]));
  }

  public viewScore() {
    this.store.dispatch(new Navigate([`/score/${this.case.genUID}`]));
  }
}
