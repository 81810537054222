import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { SharedModule } from '../../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { TranslateService } from '@ngx-translate/core';
import { CoreModule } from '../../core/core.module';
import { MatGridListModule } from '@angular/material/grid-list';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { RegisterModule } from '../register/register.module';
import { ResetPasswordModule } from '../reset-password/reset-password.module';
import { EmailActionModule } from '../email-action/email-action.module';

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    CoreModule,
    MatGridListModule,
    MatButtonModule,
    RouterModule,
    MatDividerModule,
    MatCardModule,
    RegisterModule,
    ResetPasswordModule,
    EmailActionModule,
  ],
  exports: [LoginComponent],
  providers: [TranslateService],
})
export class LoginModule {}
