import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      abortText: string;
      confirmText: string;
      titleText: string;
      descText: string;
    }
  ) {}

  confirm() {
    this.dialogRef.close(true);
  }

  abort() {
    this.dialogRef.close(false);
  }
}
