import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'emptyIndicator' })
export class EmptyIndicatorPipe implements PipeTransform {
  transform(value: string | number | boolean): string {
    return value === null || value === undefined || value === ''
      ? '/'
      : `${value}`;
  }
}
