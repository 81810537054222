import {
  Component,
  forwardRef,
  OnDestroy,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-password-form',
  templateUrl: './password-form.component.html',
  styleUrls: ['./password-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PasswordFormComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: PasswordFormComponent,
      multi: true,
    },
  ],
})
export class PasswordFormComponent
  implements ControlValueAccessor, Validator, OnInit, OnDestroy
{
  public onChange: (newPassword: any) => void;
  public onTouch: () => {};
  public onValidChange: () => void;

  public destroy$ = new Subject<void>();

  public formGroup = new FormGroup(
    {
      password: new FormControl(undefined, [
        Validators.required,
        Validators.minLength(8),
      ]),
      repeatPassword: new FormControl(),
    },
    [(control) => this.validate(control)]
  );

  public ngOnInit() {
    this.formGroup.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((controller: AbstractControl) => {
        if (controller && this.onChange) {
          this.onChange(controller);
          this.onTouch();
        }
      });
    this.formGroup.statusChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.onValidChange());
  }
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(obj: any): void {
    this.formGroup.setValue({
      password: obj,
      repeatPassword: obj,
    });
  }

  registerOnChange(fn: (newPassword: any) => {}): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  validate({ value }: AbstractControl): ValidationErrors {
    if (!value) {
      return null;
    }
    if (value.password !== value.repeatPassword) {
      return {
        passwordNotMatch: true,
      };
    }

    return null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidChange = fn;
  }
}
