import { NgxsFirestore } from '@ngxs-labs/firestore-plugin';
import { AdminState } from './admin.state';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AdminFirestore extends NgxsFirestore<AdminState> {
  protected path = 'verification-tokens';
}
