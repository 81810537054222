import { NgxsFirestore } from '@ngxs-labs/firestore-plugin';
import { CasesState } from './cases.state';
import { Injectable } from '@angular/core';
import { Case } from '../../models/case.model';

@Injectable({
  providedIn: 'root',
})
export class CasesFirestore extends NgxsFirestore<Case> {
  protected path = 'cases';

  public setPath(path: string) {
    this.path = path;
  }
  public getPath() {
    return this.path;
  }
}
