import { Pipe, PipeTransform } from '@angular/core';
import { Case } from '../../../core/models/case.model';

@Pipe({
  name: 'caseSort',
})
export class CaseSortPipe implements PipeTransform {
  transform(value: Case[]): Case[] {
    console.log(value);
    return value.sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime());
  }
}
