import { Case } from '../../models/case.model';

export namespace CasesActions {
  export class AddCase {
    static readonly type = '[Cases] Add (or edit) Case';
    constructor(public caseToBeAdded: Case) {}
  }

  export class DeleteCase {
    static readonly type = '[Cases] Delete Case';
    constructor(public caseToBeDeleted: Case) {}
  }

  export class CompleteCase {
    static readonly type = '[Cases] Add (or edit) Case';
    constructor(public caseToBeAdded: Case) {}
  }

  export class GetAll {
    public static readonly type = '[Cases] GetAll';
    constructor() {}
  }

  export class ClearSearch {
    public static readonly type = '[Case] ClearSearch';
  }

  export class UpdateSearch {
    public static readonly type = '[Case] UpdateSearchTerm';

    /**
     * Will not override, use clear for that!
     */
    constructor(
      public opts: {
        searchTerm?: string;
        dateRange?: {
          start?: Date;
          end?: Date;
        };
      }
    ) {}
  }
}
