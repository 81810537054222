import { ElementRef, Renderer2 } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatStep } from '@angular/material/stepper';
import {
  BehaviorSubject,
  Observable,
  PartialObserver,
  Subject,
  Subscriber,
  Unsubscribable,
} from 'rxjs';

export class Warning extends Subject<Warning> {
  public dismissed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public class: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public icon: BehaviorSubject<string> = new BehaviorSubject<string>('');

  // REVIEW: I can build yellow highlighting of the input-boxes,
  // but it didn't really look _that_ great when I tried it.
  // IMHO the warning icon with description next to the input is sufficient

  constructor(
    public readonly warning: string,
    public readonly value: string,
    public readonly control: ElementRef,
    public readonly step: MatStep,
    public readonly type: WarningType,
    public readonly opts: {
      showAsAction: boolean;
    } = null
  ) {
    super();

    this.opts = {
      showAsAction: true,
      ...(this.opts ?? {}),
    };

    this.dismissed.subscribe((val) => {
      if (this.dismissable() && val) {
        this.class.next('dismissed');
        this.icon.next('check_circle_outline');
      } else {
        if (!this.canLock()) {
          this.class.next('info');
          this.icon.next('info_outline');
        } else if (this.dismissable()) {
          this.class.next('warning');
          this.icon.next('error_outline');
        } else {
          this.class.next('error');
          this.icon.next('block');
        }
      }

      this.dispatchUpdate();
    });
    this.dismissed.next(false);
  }

  private dispatchUpdate(): void {
    this.next(this);
  }

  goto(): void {
    if (this.step == null) {
      return;
    }
    this.step.select();
    if (this.control == null) {
      return;
    }
    setTimeout(() => {
      this.control.nativeElement.focus();
    }, 200);
  }

  dismissToggle(event: MatCheckboxChange): void {
    this.dismissed.next(event.source.checked);
  }

  dismissable(): boolean {
    return this.type === WarningType.dismissable;
  }

  canLock(): boolean {
    return (
      this.type === WarningType.error || this.type === WarningType.dismissable
    );
  }

  doesLock(): boolean {
    return this.canLock() && !this.dismissed.value;
  }

  severity(): number {
    switch (this.type) {
      case WarningType.error:
        return 0;
      case WarningType.info:
        return 1;
      case WarningType.dismissable:
        return 2;
    }
  }
}

export enum WarningType {
  info,
  dismissable,
  error,
}
