import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Case } from 'src/app/core/models/case.model';
import { Select, Actions, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { CasesActions } from 'src/app/core/states/Cases/cases.actions';
import { CasesState } from 'src/app/core/states/Cases/cases.state';
import { FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';

@Component({
  selector: 'app-case-list',
  templateUrl: './case-list.component.html',
  styleUrls: ['./case-list.component.scss'],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }],
})
export class CaseListComponent implements OnInit, OnDestroy {
  constructor(
    public store: Store,
    @Inject(MAT_DATE_LOCALE) private locale: string,
    private adapter: DateAdapter<any>
  ) {}

  public destroy$ = new Subject<void>();

  public searchForm = new FormControl();
  public range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  @Select(CasesState.searchedOpenCases) public nonCompletedCases: Observable<
    Case[]
  >;
  @Select(CasesState.searchedCompletedCases) public completedCases: Observable<
    Case[]
  >;

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.store.dispatch(new CasesActions.ClearSearch());
    this.searchForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((newSearchTerm) => {
        this.store.dispatch(
          new CasesActions.UpdateSearch({ searchTerm: newSearchTerm })
        );
      });
    this.range.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((range) =>
      this.store.dispatch(
        new CasesActions.UpdateSearch({
          dateRange: {
            ...range,
          },
        })
      )
    );

    console.log('navLang', navigator.language);
    let lang = navigator.language;
    if (lang.includes('US')) {
      console.log('navLangtrue');
      lang = 'en-GB';
    }
    console.log('navLang2', lang);
    this.adapter.setLocale(lang);
    this.store.dispatch(new CasesActions.GetAll());
  }

  clearSearch() {
    this.store.dispatch(new CasesActions.ClearSearch());
    this.searchForm.reset();
    this.range.reset();
  }
}
