import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-warning-box',
  templateUrl: './warning-box.component.html',
  styleUrls: ['./warning-box.component.scss'],
})
export class WarningBoxComponent implements OnInit {
  @Input()
  warning: any;

  @Input()
  isFormLocked: boolean;

  @Input()
  smallWarning = false;

  constructor() {}

  ngOnInit(): void {}
}
