import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './bcs-dialog.component.html',
  styleUrls: ['../dialogs.scss', './bcs-dialog.component.scss'],
})
export class BcsDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<BcsDialogComponent>) {}
  ENTRIES: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  ngOnInit(): void {}
}
