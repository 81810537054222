import { Vet } from '../../models/vet.model';

export namespace VetActions {
  export class GetVet {
    static readonly type = '[Vets] Get own Data';
  }

  export class SetVet {
    static readonly type = '[Vets] Set Data';
    constructor(public vet: Vet) {}
  }

  export class SetLang {
    static readonly type = '[Vets] Set Lang';
    constructor(public userId: string, public lang: string) {}
  }

  export class ChangeParticipation {
    static readonly type = '[Vets] Change Participation';
    constructor(public id: string, public takesPart: boolean) {}
  }
}
