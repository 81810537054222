import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store, Select } from '@ngxs/store';
import { RegisterUser } from 'src/app/core/states/User/register-user.action';
import { UserState, AuthStateModel } from '../../core/states/User/user.state';
import { Observable } from 'rxjs';
import { MatchValuesValidator } from 'src/app/shared/validators/match.validator';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  registerForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.email, Validators.required]),
    password: new FormControl(),
    vetName: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    phonenumber: new FormControl('', [Validators.required]),
    postcode: new FormControl('', [Validators.required]),
    street: new FormControl('', [Validators.required]),
    housenumber: new FormControl('', []),
    acceptTos: new FormControl(false, [Validators.requiredTrue]),
  });

  @Select((state) => (state.auth as AuthStateModel).errorCode)
  error$: Observable<string>;

  @Select((state) => (state.auth as AuthStateModel).registrationInfoText)
  errorText$: Observable<string>;

  constructor(private store: Store, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.email) {
        this.registerForm.controls.email.setValue(params.email);
      }
    });
  }

  public register() {
    console.log(this.registerForm.value);
    this.store.dispatch(
      new RegisterUser({
        vet: {
          name: this.registerForm.value.name,
          veterinaryCompany: this.registerForm.value.vetName,
          address: {
            country: this.registerForm.value.country,
            postcode: this.registerForm.value.postcode,
            city: this.registerForm.value.city,
            street: this.registerForm.value.street,
            housenumber: this.registerForm.value.housenumber,
          },
          phonenumber: this.registerForm.value.phonenumber,
          studyParticipation: false,
        },
        email: this.registerForm.value.email,
        password: this.registerForm.value.password.password,
      })
    );
  }
}
