import {
  Component,
  OnInit,
  AfterContentChecked,
  ChangeDetectorRef,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { saveAs } from 'file-saver';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Case } from 'src/app/core/models/case.model';
import { GetAll } from 'src/app/core/states/Admin/admin.state';
import {
  AdminService,
  UserDataResponse,
} from 'src/app/core/services/admin.service';
import { VetActions } from 'src/app/core/states/User/vets.actions';
import { FormControl } from '@angular/forms';
import { CaseService } from 'src/app/core/services/case.service';
import firebase from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements AfterContentChecked, OnInit {
  public openCasesNum: Observable<number>;
  public completedCasesNum: Observable<number>;
  public completedCasesStudyNum: Observable<number>;
  public cases = new BehaviorSubject<Case[]>([]);
  public completedCases: Observable<Case[]>;
  public openCases: Observable<Case[]>;
  public completedCasesStudy: Observable<Case[]>;

  @Select((state) => state.auth.user)
  public firebaseUser: Observable<firebase.User>;

  public userData: UserDataResponse[];

  public inputFormControl = new FormControl();

  public constructor(
    public store: Store,
    public adminService: AdminService,
    private cdr: ChangeDetectorRef,
    public caseService: CaseService,
    private firestore: AngularFirestore
  ) {}

  public ngOnInit(): void {
    this.completedCases = this.cases.pipe(
      map((cases) => cases.filter((currCase) => currCase.completed))
    );
    this.openCases = this.cases.pipe(
      map((cases) => cases.filter((currCase) => !currCase.completed))
    );
    this.completedCasesStudy = this.cases.pipe(
      map((cases) =>
        cases.filter(
          (currCase) => currCase.completed && currCase.studyParticipation
        )
      )
    );
    return this.reload();
  }

  public reload() {
    this.adminService
      .getUsers()
      .then((users) => {
        this.userData = users.sort((a: UserDataResponse, b: UserDataResponse) =>
          a.email.localeCompare(b.email)
        );
      })
      .catch((error) => {
        console.error('Failed getting users', error);
      });
    this.firestore
      .collectionGroup('cases')
      .get()
      .toPromise()
      .then((querySnapshot) => {
        const fixDate = (inputDate: string | Date) => {
          if (!inputDate) {
            return;
          }
          if (new Date(inputDate).toString() !== 'Invalid Date') {
            return new Date(inputDate);
          }
          return new Date(
            // @ts-ignore
            inputDate.seconds * 1000 +
              // @ts-ignore
              inputDate.nanoseconds / 1000000
          );
        };

        const cases = this.addScore(
          querySnapshot.docs.map((snapshot) => ({
            ...(snapshot.data() as any),
            uid: snapshot.ref.parent.parent?.id as string,
          }))
        );
        cases.forEach((newCase: any) => {
          newCase.updatedAt = fixDate(newCase.updatedAt);
          newCase.createdAt = fixDate(newCase.createdAt);
        });
        this.cases.next(cases as any);
      });
    this.cdr.detectChanges();
  }

  public ngAfterContentChecked(): void {
    this.openCasesNum = this.openCases.pipe(map((cases) => cases.length));
    this.completedCasesNum = this.completedCases.pipe(
      map((cases) => cases.length)
    );
    this.completedCasesStudyNum = this.completedCases.pipe(
      map((cases) => {
        return cases.filter((currCase) => currCase.studyParticipation).length;
      })
    );
  }

  public changeAdminState(
    uid: string,
    admin: boolean,
    disableReload = false
  ): void {
    this.adminService.changeAdminState(uid, admin).then((res) => {
      if (!disableReload) {
        this.reload();
      }
    });
  }

  public addToStudyBtn() {
    if (!this.inputFormControl.value) {
      return;
    }
    const uids = (this.inputFormControl.value as string).split(/\s|,|;/);

    this.store
      .dispatch(
        uids
          .map((s) => s.trim())
          .filter((s) => this.userData.some((user) => user.uid.includes(s)))
          .filter((s) => s.length > 0)
          .map((userId) => new VetActions.ChangeParticipation(userId, true))
      )
      .toPromise()
      .then(() => this.reload());
  }

  public changeStudyParticipationStatus(uid: string, participation: boolean) {
    return this.store.dispatch(
      new VetActions.ChangeParticipation(uid, participation)
    );
  }

  public safeCSV(input: string | number | boolean | Date): string {
    if (input instanceof Date) {
      return `${input.getUTCFullYear()}-${input.getUTCMonth()}-${input.getUTCDate()}`;
    }
    if (typeof input === 'string' || (input as any) instanceof String) {
      // @ts-ignore
      let removedWhitespace = input.trim();
      while (
        ['=', '+', '-', '@', 0x09, 0x0d].includes(removedWhitespace.charAt(0))
      ) {
        removedWhitespace = removedWhitespace.slice(1);
      }
      return `"${removedWhitespace}"`;
    }
    if (input === undefined || input === null) {
      return '';
    }
    return `${input}`;
  }

  // from https://stackoverflow.com/a/53620876
  propertiesToArray(objToArr): string[] {
    const isObject = (val) =>
      val &&
      typeof val === 'object' &&
      !Array.isArray(val) &&
      !(val instanceof Date);

    const addDelimiter = (a, b) => (a ? `${a}.${b}` : b);

    const paths = (obj = {}, head = '') => {
      return Object.entries(obj).reduce((product, [key, value]) => {
        const fullPath = addDelimiter(head, key);
        return isObject(value)
          ? product.concat(paths(value, fullPath))
          : product.concat(fullPath);
      }, []);
    };

    return paths(objToArr);
  }

  // see https://www.geeksforgeeks.org/flatten-javascript-objects-into-a-single-depth-object/
  flattenObj(ob: any) {
    const result = {};
    for (const i in ob) {
      if (ob[i] && typeof ob[i].getMonth === 'function') {
        result[i] = ob[i];
      } else if (typeof ob[i] === 'object' && !Array.isArray(ob[i])) {
        const temp = this.flattenObj(ob[i]);
        Object.keys(temp).map((j) => {
          result[i + '.' + j] = temp[j];
        });
      } else {
        result[i] = ob[i];
      }
    }
    return result;
  }

  public addScore(caseToAddScore: Case[]) {
    return caseToAddScore.map((c) => {
      return {
        ...c,
        score: this.caseService.calculateScore(c),
      };
    });
  }

  public async exportUsers() {
    this.exportCSV(this.userData, 'users.csv');
  }

  public async exportAllCases() {
    await this.store.dispatch(new GetAll()).toPromise();
    this.exportCSV(await this.cases.pipe(take(1)).toPromise(), 'all-cases.csv');
  }

  public async exportCompleteCases() {
    await this.store.dispatch(new GetAll()).toPromise();
    this.exportCSV(
      await this.completedCases.pipe(take(1)).toPromise(),
      'complete-cases.csv'
    );
  }

  public async exportCompleteStudyCases() {
    await this.store.dispatch(new GetAll()).toPromise();
    this.exportCSV(
      (await this.completedCases.pipe(take(1)).toPromise()).filter(
        (currcase) => currcase.studyParticipation
      ),
      'study-cases.csv'
    );
  }

  public async exportIncompleteCases() {
    this.store.dispatch(new GetAll());
    this.exportCSV(
      await this.openCases.pipe(take(1)).toPromise(),
      'incomplete-cases.csv'
    );
  }

  public async exportCSV(objs: any[], name = 'hamlet.csv'): Promise<void> {
    const flattedEmptyObj = this.flattenObj(this.createEmptyCase());
    const flatted = objs.map((c) => this.flattenObj(c));
    const headers = [...flatted, flattedEmptyObj]
      .reduce<string[]>(
        (pre, flatObj) => [
          ...new Set([...pre, ...this.propertiesToArray(flatObj)]),
        ],
        []
      )
      .sort((a, b) => {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });
    const firstRow = headers.map((col) => `"${col}"`).join(',');
    // Map each case to one line
    const content = flatted
      .map((c) =>
        headers
          .map((col) => {
            return this.safeCSV(c[col]);
          })
          .join(',')
      )
      .join('\n');
    const csv = firstRow + '\n' + content;
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, name);
  }

  // Little Helper Method to generate an empty case, for the headers
  private createEmptyCase() {
    return {
      genUID: '',
      petID: '',
      forename: '',
      surname: '',
      age: {
        years: 0,
        months: 0,
      },
      sex: '',
      breed: '',
      comorbidities: {
        otherCardiac: '',
        chronicKidney: '',
        endocrine: '',
        liver: '',
        neoplasia: '',
        other: '',
        details: '',
      },
      medication: {
        acei: '',
        furosemideTorasemide: '',
        pimobendan: '',
        spironolactone: '',
        endocrine: '',
        antiArrhythmics: '',
        chemotherapy: '',
        details: '',
      },
      history: {
        appetite: false,
        cough: false,
        exerciseTolerance: false,
      },
      physicalExam: {
        bcs: 1,
        heartrate: 1,
        heartrhythm: 'regular',
        murmur: 'soft',
        respiratoryRate: 0,
        weight: 0,
      },
      bloodTestResult: {
        NTproBNP: 0,
        Creatinine: 0,
        cTnl: 0,
        Albumin: 0,
        ALKP: 0,
        ALT: 0,
        Bilirubin: 0,
        BUN: 0,
        Calcium: 0,
        Chloride: 0,
        Cholesterol: 0,
        GGT: 0,
        Globulin: 0,
        Glucose: 0,
        Phosphate: 0,
        Potassium: 0,
        SDMA: 0,
        Sodium: 0,
      },
      imageTestResult: {
        VHS: 0,
        VLAS: 0,
        LAAo: 0,
        LVIDDN: 0,
      },
      completed: true,
      sentMail: true,
      updatedAt: new Date(),
      createdAt: new Date(),
      studyParticipation: true,
    };
  }
}
