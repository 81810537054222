<h1 mat-dialog-title>{{ "case.physical.bcs.@" | translate }}</h1>
<div mat-dialog-content>
  <table mat-table [dataSource]="ENTRIES">
    <ng-container matColumnDef="score">
      <th mat-header-cell *matHeaderCellDef>
        {{ "case.physical.bcs.score" | translate }}
      </th>
      <td mat-cell *matCellDef="let score">
        {{ score }}
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>
        {{ "case.physical.bcs.description" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let score"
        [attr.data-cy]="'bcs-description-' + score"
      >
        {{ "case.physical.bcs." + score | translate }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['score', 'description']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['score', 'description']"></tr>
  </table>
  <br />
  <span class="source">
    <p>{{ "case.source.title" | translate }}:</p>
    <p>{{ "case.source.content-bcs" | translate }}</p>
  </span>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    (click)="this.dialogRef.close()"
    data-cy="close-bcs-dialog"
  >
    {{ "global.close" | translate }}
  </button>
</div>
