import { KeyValue } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Warning } from 'src/app/pages/case-create/warning';

@Pipe({ name: 'warningFilterPipe' })
export class WarningFilterPipe implements PipeTransform {
  transform(
    warnings: KeyValue<string, Warning>[],
    severity: number
  ): KeyValue<string, Warning>[] {
    return warnings.filter((a) => {
      return a.value.severity() === severity;
    });
  }
}
