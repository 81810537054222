import { NgxsFirestore } from '@ngxs-labs/firestore-plugin';
import { Injectable } from '@angular/core';
import { UserState } from './user.state';
import { Vet } from '../../models/vet.model';

@Injectable({
  providedIn: 'root',
})
export class VetsFirestore extends NgxsFirestore<Vet> {
  protected path = 'vets';
}
