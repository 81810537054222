import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'scorePipe' })
export class ScorePipe implements PipeTransform {
  transform(value: number): string {
    if (value) {
      return `${value.toLocaleString(undefined, {
        maximumFractionDigits: 0,
      })}%`;
    }
    return '';
  }
}
