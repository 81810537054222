export const environment = {
  production: true,
  firebaseEmulator: false,
  firebase: {
    apiKey: 'AIzaSyDhMf7gpRfF4dacCf09gGQwBWEDAfJ1emE',
    authDomain: 'vetvisetest.firebaseapp.com',
    databaseURL: 'https://vetvisetest.firebaseio.com',
    projectId: 'vetvisetest',
    storageBucket: 'vetvisetest.appspot.com',
    messagingSenderId: '1094124904743',
    appId: '1:1094124904743:web:249a4621f79acb71ca4902',
    measurementId: 'G-9Z7NL425V2',
  },
  apiBasePath: 'https://us-central1-vetvisetest.cloudfunctions.net/',
  languages: ['en', 'de'],
};
