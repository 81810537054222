import { Vet } from '../../models/vet.model';

export class RegisterUser {
  static readonly type = '[User] register';

  constructor(
    public opts: {
      email: string;
      password: string;
      vet: Vet;
    }
  ) {}
}
