import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserActions } from 'src/app/core/states/User/user.actions';
import { UserState } from 'src/app/core/states/User/user.state';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm = new FormGroup({
    email: new FormControl('', [Validators.email]),
    password: new FormControl(''),
  });

  @Select(UserState.loginInfoText)
  public infoTextLogin: Observable<string>;

  public destroy$ = new Subject<void>();

  public currState: 'login' | 'register' | 'reset-password' | 'email-action' =
    'login';

  constructor(private store: Store, public route: ActivatedRoute) {}

  public ngOnInit() {
    this.route.url.pipe(takeUntil(this.destroy$)).subscribe((url) => {
      if (url.length === 0) {
        this.currState = 'login';
        return;
      }

      switch (url[0].path) {
        case 'login':
        case 'register':
        case 'reset-password':
        case 'email-action':
          this.currState = url[0].path;
          break;
        default:
          this.currState = 'login';
      }
      console.log(url[0].path, this.currState);
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public async login() {
    await this.store
      .dispatch([
        new UserActions.LoginUser(
          this.loginForm.value.email,
          this.loginForm.value.password
        ),
      ])
      .toPromise();
  }

  public goToReset() {
    this.store.dispatch(
      new Navigate(['/reset-password'], {
        email: this.loginForm.value.email,
      })
    );
  }
}
