<a [routerLink]="['/login']" class="linkbtn">
  <mat-icon class="samesize">arrow_back_ios</mat-icon
  >{{ "global.back" | translate }}
</a>
<mat-card-title>{{ "reset-password.title" | translate }}</mat-card-title>
<span data-cy="error">{{ error$ | async | errorPipe }}</span>
<span data-cy="error">{{ error$ | async | errorPipe | translate }}</span>
<form [formGroup]="resetPasswordForm">
  <mat-form-field>
    <mat-label>{{ "login.email" | translate }}</mat-label>
    <input type="email" matInput formControlName="email" data-cy="email" />
    <mat-icon matSuffix>email</mat-icon>
  </mat-form-field>
</form>
<button
  id="submitBtn"
  (click)="submit()"
  [disabled]="!resetPasswordForm.valid"
  mat-flat-button
  color="primary"
  data-cy="submit"
  class="full-width"
>
  {{ "global.submit" | translate }}
</button>
