import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Endpoints } from '../endpoints';
import { take, map, tap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';

export interface UserDataResponse {
  email?: string;
  displayName?: string;
  uid: string;
  admin: boolean;
  studyParticipation: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(
    private http: HttpClient,
    private firebaseAuth: AngularFireAuth
  ) {}

  async getUsers(): Promise<UserDataResponse[]> {
    const usertoken = await this.firebaseAuth.user
      .pipe(take(1))
      .pipe(map(async (user) => await user.getIdToken()))
      .toPromise();
    return (await this.http
      .get(environment.apiBasePath + Endpoints.getUsers, {
        params: {
          idToken: usertoken,
        },
      })
      .toPromise()) as Promise<UserDataResponse[]>;
  }
  async changeAdminState(uid: string, admin: boolean): Promise<any> {
    const usertoken = await this.firebaseAuth.user
      .pipe(take(1))
      .pipe(map(async (user) => await user.getIdToken()))
      .toPromise();
    return await this.http
      .post(environment.apiBasePath + Endpoints.changeAdminState, {
        admin,
        idToken: usertoken,
        uid,
      })
      .toPromise();
  }
}
