<span
  *ngIf="warnings.has(name)"
  [class]="warnings.get(name).class | async"
  [attr.data-cy]="'warninline-' + name"
>
  <mat-icon class="samesize">{{ warnings.get(name).icon | async }}</mat-icon>
  {{ "case.warn.warnings." + warnings.get(name).warning | translate }}
  <!-- Button has been removed, because it breaks the CSS if it is inside a <mat-hint> TODO: Maybe re-add somehow (link?) -->
  <!--<button
    mat-flat-button
    *ngIf="
      warnings.get(name).dismissable() && !warnings.get(name).dismissed.value
    "
    href="javascript:void(0);"
    (click)="warnings.get(name).dismissed.next(true)"
    class="linkbtn"
    [attr.data-cy]="'warninline-resolve-' + name"
  >
    {{ "case.warn.resolve" | translate }}
  </button>-->
</span>
