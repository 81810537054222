import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Case } from '../models/case.model';
import { Vet } from '../models/vet.model';
import { CasesState } from '../states/Cases/cases.state';

@Injectable({
  providedIn: 'root',
})
export class CaseService {
  @Select((state) => state.auth.vet)
  public vet: Observable<Vet>;

  constructor(private afa: AngularFireAuth, private store: Store) {}

  public async getGenUID(): Promise<string> {
    const date = new Date();
    const p = this.store.selectSnapshot((state) => state.auth.vet);
    const str = `${p.veterinaryCompany ?? 'practice'}_${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
    while (true) {
      // will get problematic if more then 100000 cases are created daily in a single practice
      const rand = Math.floor(Math.random() * 100000);
      if (
        this.store.selectSnapshot(CasesState.case)(str + '_' + rand) ===
        undefined
      ) {
        return str + '_' + rand;
      }
    }
  }

  public calculateScore(caseToCalc: Case) {
    // Pay Attention to the sign before the equal-sign!
    const intercept = -10;
    let beta = intercept;

    if (caseToCalc.history.appetite) {
      beta += 2.99;
    }

    switch (caseToCalc.physicalExam.bcs) {
      case 4:
        beta -= 1.83;
        break;
      case 5:
        beta -= 1.39;
        break;
      case 6:
        beta -= 1.11;
        break;
      case 7:
        beta -= 1.58;
        break;
    }

    if (caseToCalc.physicalExam.bcs > 7) {
      beta -= 2.01;
    }

    switch (caseToCalc.physicalExam.murmur) {
      case 'soft':
        break;
      case 'moderate':
        beta += 0.73;
        break;
      case 'loud':
        beta += 1.59;
        break;
      case 'thrilling':
        beta += 1.93;
        break;
    }

    beta -= caseToCalc.bloodTestResult.Creatinine * 0.02;
    beta += Math.log10(caseToCalc.bloodTestResult.NTproBNP) * 3.66;

    const odds = Math.exp(beta);
    const probalility = odds / (odds + 1);

    return probalility;
  }

  risk(score: number): 'low' | 'medium' | 'high' {
    if (score <= 20) {
      return 'low';
    } else if (score >= 70) {
      return 'high';
    } else {
      return 'medium';
    }
  }

  riskColor(score: number): string {
    switch (this.risk(score)) {
      case 'high':
        return 'red';
      case 'medium':
        return '#FFC000';
      case 'low':
        return 'green';
    }
  }
}
