import { ConfirmResetComponent } from './pages/confirm-reset/confirm-reset.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { CaseListComponent } from './pages/case-list/case-list.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { CaseCreateComponent } from './pages/case-create/case-create.component';
import { AdminComponent } from './pages/admin/admin.component';
import {
  hasCustomClaim,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
  canActivate,
  customClaims,
} from '@angular/fire/auth-guard';
import { map } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { CaseScoreComponent } from './pages/case-score/case-score.component';
import { EmailActionComponent } from './pages/email-action/email-action.component';
import { ImprintComponent } from './pages/imprint/imprint.component';

const adminOnly = () => hasCustomClaim('admin');
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectAdminsToAdmin = () =>
  pipe(
    customClaims,
    map((claims) => claims.admin === 'true')
  );
const redirectLoggedIn = () => redirectLoggedInTo(['case-list']);

const routes: Routes = [
  { path: '', component: LoginComponent, ...canActivate(redirectLoggedIn) },
  {
    path: 'case-list',
    component: CaseListComponent,
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'case/:uid',
    component: CaseCreateComponent,
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'case-create/:uid',
    component: CaseCreateComponent,
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'score/:uid',
    component: CaseScoreComponent,
    // ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'case-create',
    component: CaseCreateComponent,
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'login',
    component: LoginComponent,
    ...canActivate(redirectLoggedIn),
  },
  { path: 'admin', component: AdminComponent, ...canActivate(adminOnly) },
  {
    path: 'register',
    component: LoginComponent,
    ...canActivate(redirectLoggedIn),
  },
  {
    path: 'confirm-reset',
    component: ConfirmResetComponent,
  },
  {
    path: 'reset-password',
    component: LoginComponent,
    ...canActivate(redirectLoggedIn),
  },
  {
    path: 'verify-email',
    component: EmailActionComponent,
    ...canActivate(redirectLoggedIn),
  },
  {
    path: 'email-action',
    component: LoginComponent,
  },
  {
    path: 'imprint',
    component: ImprintComponent,
  },
  {
    path: 'information',
    component: ImprintComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
