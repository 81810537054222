import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class L18nService {

  constructor(
    private translateService: TranslateService,
  ) { }

  public changeLanguage(language: string) {
    this.translateService.setDefaultLang(language);
  }
}
