import { Case } from '../../models/case.model';
import Fuse from 'fuse.js';

import { Injectable } from '@angular/core';
import {
  Action,
  NgxsOnInit,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { CasesFirestore } from './cases.firestore';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  Emitted,
  NgxsFirestoreConnect,
  StreamEmitted,
} from '@ngxs-labs/firestore-plugin';
import firebase from 'firebase';
import auth = firebase.auth;
import { CasesActions } from './cases.actions';

export interface CaseStateModel {
  cases: Case[];
  searchedCases: Case[];
  searchTerm: string;
}

@State<CaseStateModel>({
  name: 'cases',
  defaults: { cases: [], searchedCases: [], searchTerm: null },
})
@Injectable()
export class CasesState implements NgxsOnInit {
  constructor(
    private casesFS: CasesFirestore,
    private ngxsFirestoreConnect: NgxsFirestoreConnect,
    private store: Store
  ) {}

  @Selector()
  static case(state: CaseStateModel) {
    return (uid: string) => {
      return state.cases?.filter((s) => s.genUID === uid)[0];
    };
  }

  @Selector()
  static cases(state: CaseStateModel) {
    return state.cases;
  }

  @Selector()
  static openCases(state: CaseStateModel) {
    return state.cases.filter((c) => c.completed === false);
  }

  @Selector()
  static completedCases(state: CaseStateModel) {
    return state.cases.filter((c) => c.completed === true);
  }

  @Selector()
  static searchedOpenCases(state: CaseStateModel) {
    return state.searchedCases.filter((c) => c.completed === false);
  }

  @Selector()
  static searchedCompletedCases(state: CaseStateModel) {
    return state.searchedCases.filter((c) => c.completed === true);
  }

  ngxsOnInit() {
    auth().onAuthStateChanged((user: firebase.User) => {
      if (user !== null) {
        this.casesFS.setPath(`practices/${user.uid}/cases`);
        this.ngxsFirestoreConnect.connect(CasesActions.GetAll, {
          to: () => this.casesFS.collection$(),
        });
        this.ngxsFirestoreConnect.connect(CasesActions.DeleteCase, {
          to: (action: CasesActions.DeleteCase) => {
            this.casesFS.delete$(action.caseToBeDeleted.genUID);
            return this.store.dispatch(new CasesActions.GetAll());
          },
        });
        this.ngxsFirestoreConnect.connect(CasesActions.AddCase, {
          to: (action: CasesActions.AddCase) => {
            this.casesFS.update$(
              action.caseToBeAdded.genUID,
              action.caseToBeAdded
            );
            return this.store.dispatch(new CasesActions.GetAll());
          },
        });
        this.store.dispatch(new CasesActions.GetAll());
      }
    });
  }

  @Action(StreamEmitted(CasesActions.GetAll))
  get(
    ctx: StateContext<CaseStateModel>,
    { payload }: Emitted<CasesActions.GetAll, Case[]>
  ) {
    // Bad Date Format from firebase, needs to be fixed

    const fixDate = (inputDate: string | Date) => {
      if (!inputDate) {
        return;
      }
      if (new Date(inputDate).toString() !== 'Invalid Date') {
        return new Date(inputDate);
      }
      return new Date(
        // @ts-ignore
        inputDate.seconds * 1000 +
          // @ts-ignore
          inputDate.nanoseconds / 1000000
      );
    };

    payload.forEach((newCase) => {
      newCase.updatedAt = fixDate(newCase.updatedAt);
      newCase.createdAt = fixDate(newCase.createdAt);
    });
    ctx.patchState({ cases: payload, searchedCases: payload });
  }
  private setToFirstHour(date: Date): Date {
    const result = new Date(date);
    result.setHours(0);
    result.setMinutes(0);
    result.setSeconds(0);
    result.setMilliseconds(0);
    return result;
  }

  @Action(CasesActions.UpdateSearch)
  search(
    { patchState, getState }: StateContext<CaseStateModel>,
    { opts }: CasesActions.UpdateSearch
  ) {
    console.log('UPDATE');
    if (!opts) {
      patchState({
        searchTerm: null,
        searchedCases: getState().cases,
      });
    }
    const searchTerm = opts.searchTerm ?? getState().searchTerm;
    const cases = getState().cases.filter((testCase) => {
      if (!opts.dateRange || !testCase.updatedAt) {
        return true;
      }
      if (
        opts.dateRange.start &&
        this.setToFirstHour(opts.dateRange.start).toISOString() >
          testCase.createdAt.toISOString()
      ) {
        return false;
      }
      if (
        opts.dateRange.end &&
        this.setToFirstHour(opts.dateRange.end).toISOString() <
          testCase.updatedAt.toISOString()
      ) {
        return false;
      }
      return true;
    });

    const fuse = new Fuse(cases, {
      keys: ['forename', 'surname', 'genUID', 'petID'],
      useExtendedSearch: true,
      threshold: 0.1,
      ignoreLocation: true,
    });
    patchState({
      searchedCases:
        !searchTerm || searchTerm.length === 0
          ? cases
          : fuse.search(searchTerm).map((result) => result.item),
      searchTerm,
    });
  }
}
