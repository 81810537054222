import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { ContentContainerComponent } from './components/content-container/content-container.component';
import { CaseCardComponent } from './components/case-card/case-card.component';
import { RouterModule } from '@angular/router';
import { SexTranslatePipe } from './pipes/sex-translate.pipe';
import { ScorePipe } from './pipes/score.pipe';
import { MatExpansionModule } from '@angular/material/expansion';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { EmptyIndicatorPipe } from './pipes/empty-indicator.pipe';
import { CardTitlePipe } from './pipes/card-title.pipe';
import { FooterComponent } from './components/footer/footer.component';
import { WelcomeCardComponent } from './components/welcome-card/welcome-card.component';
import { WarningSortPipe } from './pipes/warning-sort.pipe';
import { PasswordFormComponent } from './components/password-form/password-form.component';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { WarningFilterPipe } from './pipes/warning-filter.pipe';
import { WarningBoxComponent } from './components/warning-box/warning-box.component';
import { ErrorPipe } from './pipes/error-pipe';
import { WarningActionFilterPipe } from './pipes/warning-action-filter.pipe';

const components = [
  CardTitlePipe,
  CaseCardComponent,
  ConfirmDialogComponent,
  ContentContainerComponent,
  EmptyIndicatorPipe,
  ErrorPipe,
  FooterComponent,
  PasswordFormComponent,
  ScorePipe,
  SexTranslatePipe,
  ToolbarComponent,
  WarningBoxComponent,
  WarningFilterPipe,
  WarningActionFilterPipe,
  WarningSortPipe,
  WelcomeCardComponent,
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    RouterModule,
    MatToolbarModule,
    TranslateModule,
    MatIconModule,
    RouterModule,
    MatButtonModule,
    MatMenuModule,
    MatListModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatCardModule,
    MatExpansionModule,
    BrowserAnimationsModule,
  ],
  exports: [...components, TranslateModule],
  providers: [TranslateService],
})
export class SharedModule {}
