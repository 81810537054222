<a [routerLink]="['/login']" class="linkbtn">
  <mat-icon class="samesize">arrow_back_ios</mat-icon
  >{{ "global.back" | translate }}
</a>
<mat-card-title>{{ "register.title" | translate }}</mat-card-title>
{{ error$ | async }}
<form [formGroup]="registerForm">
  <mat-form-field>
    <mat-label>{{ "register.name" | translate }}</mat-label>
    <input matInput formControlName="name" data-cy="name" />
    <mat-icon matSuffix>perm_identity</mat-icon>
  </mat-form-field>
  <br />
  <mat-form-field>
    <mat-label>{{ "login.email" | translate }}</mat-label>
    <input matInput formControlName="email" data-cy="email" />
    <mat-icon matSuffix>mail</mat-icon>
  </mat-form-field>
  <br />
  <app-password-form formControlName="password"></app-password-form>
  <h2 class="address-h2">{{ "register.address.title" | translate }}</h2>
  <mat-form-field>
    <mat-label>{{ "register.vetName" | translate }}</mat-label>
    <input matInput formControlName="vetName" data-cy="vetName" />
    <mat-icon matSuffix>work</mat-icon>
  </mat-form-field>
  <br />
  <div class="street-line">
    <mat-form-field>
      <mat-label>{{ "register.address.housenumber" | translate }}</mat-label>
      <input matInput formControlName="housenumber" data-cy="housenumber" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "register.address.street" | translate }}</mat-label>
      <input matInput formControlName="street" data-cy="street" />
    </mat-form-field>
  </div>
  <div class="city-line">
    <mat-form-field class="full-line">
      <mat-label>{{ "register.address.city" | translate }}</mat-label>
      <input matInput formControlName="city" data-cy="city" />
    </mat-form-field>
    <mat-form-field class="full-line">
      <mat-label>{{ "register.address.postcode" | translate }}</mat-label>
      <input matInput formControlName="postcode" data-cy="postcode" />
    </mat-form-field>
  </div>
  <mat-form-field>
    <mat-label>{{ "register.address.country" | translate }}</mat-label>
    <input matInput formControlName="country" data-cy="country" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ "login.phonenumber" | translate }}</mat-label>
    <input matInput formControlName="phonenumber" data-cy="phonenumber" />
    <mat-icon matSuffix>phone</mat-icon>
  </mat-form-field>
  <mat-checkbox data-cy="checkTos" formControlName="acceptTos">
    Accept
    <a style="display: inline" href="/information#data-privacy"
      >Terms of service and privacy policy</a
    >
  </mat-checkbox>
</form>
<button
  (click)="register()"
  [disabled]="!registerForm.valid"
  mat-flat-button
  color="primary"
  data-cy="submit"
  class="register-btn"
>
  {{ "register.buttonTitle" | translate | uppercase }}
</button>
<div data-cy="error-warnbox-register" class="error-text">
  {{ errorText$ | async }}
</div>
