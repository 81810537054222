import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Warning } from '../warning';

@Component({
  selector: 'app-input-yes-no-radio',
  templateUrl: './input-yes-no-radio.component.html',
  styleUrls: ['./input-yes-no-radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputYesNoRadioComponent,
      multi: true,
    },
  ],
})
export class InputYesNoRadioComponent
  implements OnInit, ControlValueAccessor, OnDestroy
{
  @Input()
  warnings: Map<string, Warning>;

  @Input()
  warningName: string;

  @Input()
  title: string;

  @Input()
  dataCy: string;

  formControl = new FormControl(null);

  onChange: (arg0: boolean) => void;
  onTouched: (arg0: boolean) => void;

  destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.formControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((newVal) => {
        if (this.onChange) {
          this.onChange(newVal);
          this.onTouched(newVal);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(obj: any): void {
    this.formControl.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }
}
