<app-toolbar></app-toolbar>
<app-content-container>
  <a [routerLink]="['/reset-password']" class="linkbtn">
    <mat-icon class="samesize">arrow_back_ios</mat-icon
    >{{ "global.back" | translate }}
  </a>
  <span data-cy="error">{{ error$ | async }}</span>
  <form [formGroup]="confirmResetForm">
    <mat-form-field [hidden]="hideTokenInput">
      <mat-label>{{ "global.oobCode" | translate }}</mat-label>
      <input type="text" matInput formControlName="token" data-cy="token" />
      <mat-icon matSuffix>vpn_key</mat-icon>
    </mat-form-field>
    <br />
    <mat-form-field>
      <mat-label>{{ "register.password" | translate }}</mat-label>
      <input
        type="password"
        matInput
        formControlName="password"
        data-cy="password"
      />
      <mat-icon matSuffix [class]="confirmResetForm.controls.password.status">
        {{ confirmResetForm.controls.password.valid ? "check" : "close" }}
      </mat-icon>
    </mat-form-field>
    <br />
    <mat-form-field>
      <mat-label>{{ "register.repeat" | translate }}</mat-label>
      <input
        type="password"
        matInput
        formControlName="passwordRepeat"
        data-cy="repeat"
      />
      <mat-icon
        matSuffix
        [class]="confirmResetForm.controls.passwordRepeat.status"
      >
        {{ confirmResetForm.controls.passwordRepeat.valid ? "check" : "close" }}
      </mat-icon>
    </mat-form-field>
  </form>
  <button
    id="submitBtn"
    (click)="submit()"
    [disabled]="!confirmResetForm.valid"
    mat-flat-button
    color="primary"
    data-cy="submit"
  >
    {{ "global.submit" | translate }}
  </button>
</app-content-container>
