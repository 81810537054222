import { Pipe, PipeTransform } from '@angular/core';
import { ErrorCodes } from 'src/app/core/states/User/errors.enum';

@Pipe({ name: 'errorPipe' })
export class ErrorPipe implements PipeTransform {
  transform(value: ErrorCodes): string {
    console.log(value);
    switch (value) {
      case null:
      case undefined:
        return null;
      case ErrorCodes.notVerifiedEmail:
        return 'errors.notVerifiedEmail';
      case ErrorCodes.userNotFound:
        return 'errors.userNotFound';
      case ErrorCodes.genericError:
      case ErrorCodes.badRequest:
      case ErrorCodes.missingToken:
      default:
        return 'errors.generic';
    }
  }
}
