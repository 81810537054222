import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { BcsDialogComponent } from './bcs-dialog/bcs-dialog.component';
import { Case } from 'src/app/core/models/case.model';
import { MatDialog } from '@angular/material/dialog';
import { MatStep } from '@angular/material/stepper';
import { MurmurDialogComponent } from './murmur-dialog/murmur-dialog.component';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ObservableMap } from './observable-map';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { UserState } from 'src/app/core/states/User/user.state';
import { Warning, WarningType } from './warning';
import { filter, map, startWith } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import firebase from 'firebase/app';
import User = firebase.User;
import { CasesActions } from 'src/app/core/states/Cases/cases.actions';
import { CaseService } from 'src/app/core/services/case.service';
import { CasesState } from 'src/app/core/states/Cases/cases.state';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-case-create',
  templateUrl: './case-create.component.html',
  styleUrls: ['./case-create.component.scss'],
})
export class CaseCreateComponent implements OnInit, AfterViewInit {
  constructor(
    public caseService: CaseService,
    public store: Store,
    public router: Router,
    public dialog: MatDialog,
    public renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService
  ) {}

  public genUID: string;

  public breeds: string[] = [
    'australian-shepherd',
    'beagle',
    'bichon-frise',
    'border-collie',
    'border-terrier',
    'boston-terrier',
    'brittany-spaniel',
    'chihuahua',
    'chinese-crested',
    'ckcs',
    'cocker-spaniel',
    'coton-de-tulear',
    'crossbreed',
    'dachshund',
    'english-cocker-spaniel',
    'havanese',
    'hungarian-vizsla',
    'jack-russell-terrier',
    'japanese-chin',
    'king-charles-spaniel',
    'lurcher',
    'maltese',
    'miniature-pinscher',
    'miniature-poodle',
    'miniature-schnauzer',
    'norfolk-terrier',
    'pekingese',
    'pomeranian',
    'poodle',
    'pug',
    'shetland-sheepdog',
    'shih-tzu',
    'tibetan-terrier',
    'toy-poodle',
    'whippet',
    'yorkshire-terrier',
  ];
  filteredBreeds: Observable<string[]>;

  baseDataForm = new FormGroup({
    petID: new FormControl(''),
    forename: new FormControl(''),
    surname: new FormControl(''),
  });

  patientInfoForm = new FormGroup({
    ageYears: new FormControl('', [Validators.pattern('[0-9]*')]),
    ageMonths: new FormControl('', [
      Validators.pattern('[0-9]*'),
      Validators.min(0),
      Validators.max(11),
    ]),
    sex: new FormControl(''),
    breed: new FormControl(''),
  });

  comorbiditiesForm = new FormGroup({
    comoOtherCardiac: new FormControl(null, [Validators.required]),
    comoChronicKidney: new FormControl(null, [Validators.required]),
    comoEndocrine: new FormControl(null, [Validators.required]),
    comoLiver: new FormControl(null, [Validators.required]),
    comoNeoplasia: new FormControl(null, [Validators.required]),
    comoOther: new FormControl(null, [Validators.required]),
    comoDetails: new FormControl(null),
  });

  medicationForm = new FormGroup({
    medsAcei: new FormControl(null, [Validators.required]),
    medsFurosemideTorasemide: new FormControl(null, [Validators.required]),
    medsPimobendan: new FormControl(null, [Validators.required]),
    medsSpironolactone: new FormControl(null, [Validators.required]),
    medsEndocrine: new FormControl(null, [Validators.required]),
    medsAntiArrhythmics: new FormControl(null, [Validators.required]),
    medsChemotherapy: new FormControl(null, [Validators.required]),
    medsDetails: new FormControl(null),
  });

  historyForm = new FormGroup({
    histAppetite: new FormControl(null, [Validators.required]),
    histCough: new FormControl(null, []),
    histExerciseTolerance: new FormControl(null, []),
  });

  physicalExamForm = new FormGroup({
    physBcs: new FormControl(''),
    physHeartrate: new FormControl('', [Validators.pattern('[0-9]*')]),
    physHeartrhythm: new FormControl(''),
    physMurmur: new FormControl(''),
    physRespiratoryRate: new FormControl('', [Validators.pattern('[0-9]*')]),
    physWeight: new FormControl('', [Validators.pattern('[0-9]*')]),
  });

  bloodTestForm = new FormGroup({
    NTproBNP: new FormControl('', [Validators.required]),
    Creatinine: new FormControl('', [Validators.required]),
    cTnl: new FormControl(''),
    Albumin: new FormControl(''),
    ALKP: new FormControl(''),
    ALT: new FormControl(''),
    Bilirubin: new FormControl(''),
    BUN: new FormControl(''),
    Calcium: new FormControl(''),
    Chloride: new FormControl(''),
    Cholesterol: new FormControl('', []),
    GGT: new FormControl(''),
    Globulin: new FormControl(''),
    Glucose: new FormControl(''),
    Phosphate: new FormControl(''),
    Potassium: new FormControl(''),
    SDMA: new FormControl(''),
    Sodium: new FormControl(''),
  });

  imageTestForm = new FormGroup({
    VHS: new FormControl(''),
    VLAS: new FormControl(''),
    LAAo: new FormControl(''),
    LVIDDN: new FormControl(''),
  });

  /**
   * Only set if case already existed
   */
  public currentlyLoadedCase = new BehaviorSubject<Case>(null);

  @Select(UserState.user)
  public user: Observable<User>;

  public practice: Observable<string>;
  @ViewChild('baseDataStep') baseDataStep: MatStep;
  @ViewChild('patientInfoStep') patientInfoStep: MatStep;
  @ViewChild('comorbiditiesStep') comorbiditiesStep: MatStep;
  @ViewChild('medicationStep') medicationStep: MatStep;
  @ViewChild('historyStep') historyStep: MatStep;
  @ViewChild('physicalExamStep') physicalExamStep: MatStep;
  @ViewChild('bloodExamStep') bloodExamStep: MatStep;
  @ViewChild('imageStep') imageStep: MatStep;

  @ViewChild('petID') elemPetID: ElementRef;
  @ViewChild('forename') elemForename: ElementRef;
  @ViewChild('surname') elemSurname: ElementRef;
  @ViewChild('age') elemAge: ElementRef;
  @ViewChild('sex') elemSex: ElementRef;
  @ViewChild('breed') elemBreed: ElementRef;
  @ViewChild('comoOtherCardiac') elemComoOtherCardiac: ElementRef;
  @ViewChild('comoChronicKidney') elemComoChronicKidney: ElementRef;
  @ViewChild('comoEndocrine') elemComoEndocrine: ElementRef;
  @ViewChild('comoLiver') elemComoLiver: ElementRef;
  @ViewChild('comoNeoplasia') elemComoNeoplasia: ElementRef;
  @ViewChild('comoOther') elemComoOther: ElementRef;
  @ViewChild('medsAcei') elemMedsAcei: ElementRef;
  @ViewChild('medsFurosemideTorasemide')
  elemMedsFurosemideTorasemide: ElementRef;
  @ViewChild('medsPimobendan') elemMedsPimobendan: ElementRef;
  @ViewChild('medsSpironolactone') elemMedsSpironolactone: ElementRef;
  @ViewChild('medsEndocrine') elemMedsEndocrine: ElementRef;
  @ViewChild('medsAntiArrythmics') elemMedsAntiArrythmics: ElementRef;
  @ViewChild('medsChemotherapy') elemMedsChemotherapy: ElementRef;
  @ViewChild('histAppetite') elemHistAppetite: ElementRef;
  @ViewChild('histCough') elemHistCough: ElementRef;
  @ViewChild('histExerciseTolerance') elemHistExerciseTolerance: ElementRef;
  @ViewChild('physBcs') elemPhysBcs: ElementRef;
  @ViewChild('physHeartrate') elemPhysHeartrate: ElementRef;
  @ViewChild('physHeartrhythm') elemPhysHeartrhythm: ElementRef;
  @ViewChild('physHeartrhythmOther') elemPhysHeartrhythmOther: ElementRef;
  @ViewChild('physMurmur') elemPhysMurmur: ElementRef;
  @ViewChild('physRespiratoryRate') elemPhysRespiratoryRate: ElementRef;
  @ViewChild('physWeight') elemPhysWeight: ElementRef;
  @ViewChild('bloodNTproBNP') elemBloodNTproBNP: ElementRef;
  @ViewChild('bloodCeratine') elemBloodCeratine: ElementRef;
  @ViewChild('bloodcTnl') elemBloodcTnl: ElementRef;
  @ViewChild('bloodAlbumin') elemBloodAlbumin: ElementRef;
  @ViewChild('bloodALKP') elemBloodALKP: ElementRef;
  @ViewChild('bloodALT') elemBloodALT: ElementRef;
  @ViewChild('bloodBilirubin') elemBloodBilirubin: ElementRef;
  @ViewChild('bloodBUN') elemBloodBUN: ElementRef;
  @ViewChild('bloodCalcium') elemBloodCalcium: ElementRef;
  @ViewChild('bloodChloride') elemBloodChloride: ElementRef;
  @ViewChild('bloodCholesterol') elemBloodCholesterol: ElementRef;
  @ViewChild('bloodGGT') elemBloodGGT: ElementRef;
  @ViewChild('bloodGlobulin') elemBloodGlobulin: ElementRef;
  @ViewChild('bloodGlucose') elemBloodGlucose: ElementRef;
  @ViewChild('bloodPhosphate') elemBloodPhosphate: ElementRef;
  @ViewChild('bloodPotassium') elemBloodPotassium: ElementRef;
  @ViewChild('bloodSDMA') elemBloodSDMA: ElementRef;
  @ViewChild('bloodSodium') elemBloodSodium: ElementRef;

  @ViewChild('imageVHS') elemImageVHS: ElementRef;
  @ViewChild('imageVLAS') elemImageVLAS: ElementRef;
  @ViewChild('imageLAAo') elemImageLAAo: ElementRef;
  @ViewChild('imageLVIDDN') elemImageLVIDDN: ElementRef;

  warnings: ObservableMap<string, Warning> = new ObservableMap<
    string,
    Warning
  >();
  lockSubmit = false;

  get isFormLocked(): boolean {
    return this.currentlyLoadedCase?.getValue()?.completed;
  }

  async ngOnInit(): Promise<void> {
    const params = await this.activatedRoute.params.pipe(take(1)).toPromise();
    if (params.uid) {
      await this.store.dispatch(new CasesActions.GetAll()).toPromise();
      const cases = await this.store.selectOnce(CasesState.cases).toPromise();
      const thisCase = cases.find((arg) => arg.genUID === params.uid);
      this.currentlyLoadedCase.next(thisCase);
      this.genUID = thisCase.genUID;
      this.baseDataForm.setValue({
        petID: thisCase.petID,
        forename: thisCase.forename,
        surname: thisCase.surname,
      });
      this.patientInfoForm.setValue({
        ageYears: thisCase.age.years,
        ageMonths: thisCase.age.months,
        sex: thisCase.sex,
        breed: thisCase.breed,
      });
      this.comorbiditiesForm.setValue({
        comoOtherCardiac: thisCase.comorbidities.otherCardiac,
        comoChronicKidney: thisCase.comorbidities.chronicKidney,
        comoEndocrine: thisCase.comorbidities.endocrine,
        comoLiver: thisCase.comorbidities.liver,
        comoNeoplasia: thisCase.comorbidities.neoplasia,
        comoOther: thisCase.comorbidities.other,
        comoDetails: thisCase.comorbidities.details ?? '',
      });
      this.medicationForm.setValue({
        medsAcei: thisCase.medication.acei,
        medsFurosemideTorasemide: thisCase.medication.furosemideTorasemide,
        medsPimobendan: thisCase.medication.pimobendan,
        medsSpironolactone: thisCase.medication.spironolactone,
        medsEndocrine: thisCase.medication.endocrine,
        medsAntiArrhythmics: thisCase.medication.antiArrhythmics,
        medsChemotherapy: thisCase.medication.chemotherapy,
        medsDetails: thisCase.medication.details ?? '',
      });
      this.historyForm.setValue({
        histAppetite: thisCase.history.appetite,
        histCough: thisCase.history.cough,
        histExerciseTolerance: thisCase.history.exerciseTolerance,
      });
      this.physicalExamForm.setValue({
        physBcs: thisCase.physicalExam.bcs,
        physHeartrate: thisCase.physicalExam.heartrate,
        physHeartrhythm: thisCase.physicalExam.heartrhythm,
        physMurmur: thisCase.physicalExam.murmur,
        physRespiratoryRate: thisCase.physicalExam.respiratoryRate,
        physWeight: thisCase.physicalExam.weight,
      });
      this.bloodTestForm.patchValue({
        ...thisCase.bloodTestResult,
      });
      this.imageTestForm.patchValue({
        ...thisCase.imageTestResult,
      });
      const forms = [
        this.baseDataForm,
        this.patientInfoForm,
        this.comorbiditiesForm,
        this.medicationForm,
        this.historyForm,
        this.physicalExamForm,
        this.bloodTestForm,
        this.imageTestForm,
      ];
      if (thisCase.completed) {
        forms.forEach((form) => form.disable());
        [
          this.baseDataStep,
          this.patientInfoStep,
          this.comorbiditiesStep,
          this.medicationStep,
          this.historyStep,
          this.physicalExamStep,
          this.bloodExamStep,
          this.imageStep,
        ].forEach((step) => (step.completed = true));
      }
    } else {
      this.caseService.getGenUID().then((genUID) => {
        this.genUID = genUID;
      });
    }
    this.filteredBreeds = this.patientInfoForm.controls.breed.valueChanges.pipe(
      startWith(''),
      map((value) =>
        this.breeds.filter((it) =>
          it
            .replace(' ', '')
            .toLowerCase()
            .includes(value.replace(' ', '').toLowerCase())
        )
      )
    );
    this.warnings.observable.subscribe(() => {
      let lock = false;
      for (const val of this.warnings.values()) {
        lock = lock || val.doesLock();
      }
      this.lockSubmit = lock;
    });
  }

  async ngAfterViewInit(): Promise<void> {
    this.updateValidityWarning();
    // Show a warning for empty required inputs
    this.baseDataForm.valueChanges.subscribe(() => {
      this.updateValidityWarning();
    });
    this.patientInfoForm.valueChanges.subscribe(() => {
      this.updateValidityWarning();
    });
    this.comorbiditiesForm.valueChanges.subscribe(() => {
      this.updateValidityWarning();
    });
    this.medicationForm.valueChanges.subscribe(() => {
      this.updateValidityWarning();
    });
    this.historyForm.valueChanges.subscribe(() => {
      this.updateValidityWarning();
    });
    this.physicalExamForm.valueChanges.subscribe(() => {
      this.updateValidityWarning();
    });
    this.bloodTestForm.valueChanges.subscribe(() => {
      this.updateValidityWarning();
    });

    // Register custom validators that are able to generate Warning objects
    this.patientInfoForm.controls.ageYears.valueChanges.subscribe(
      (age: number) => {
        if (age < 6) {
          this.warnings.set(
            'patient.age',
            new Warning(
              'patient.age.toolow',
              age.toString(),
              this.elemAge,
              this.patientInfoStep,
              WarningType.error
            )
          );
        } else if (age > 18) {
          this.warnings.set(
            'patient.age',
            new Warning(
              'check',
              age.toString(),
              this.elemAge,
              this.patientInfoStep,
              WarningType.dismissable
            )
          );
        } else {
          this.warnings.delete('patient.age');
        }
      }
    );

    const validBreeds = this.breeds.map((breed) =>
      this.translate.instant(`case.patient.breed.${breed}`)
    );

    // this.patientInfoForm.controls.breed.valueChanges.subscribe(
    //   (breed: string) => {
    //     if (validBreeds.includes(breed)) {
    //       this.warnings.delete('patient.breed.@');
    //     } else {
    //       this.warnings.set(
    //         'patient.breed.@',
    //         new Warning(
    //           'patient.breed',
    //           breed.toString(),
    //           this.elemBreed,
    //           this.patientInfoStep,
    //           WarningType.info,
    //           { showAsAction: false }
    //         )
    //       );
    //     }
    //   }
    // );

    const subscribeComorbidity = this.subscribeCheckbox(
      'comorbidity',
      this.comorbiditiesStep
    );
    subscribeComorbidity(
      this.comorbiditiesForm.controls.comoOtherCardiac,
      'comorbidity.other-cardiac'
    );
    subscribeComorbidity(
      this.comorbiditiesForm.controls.comoChronicKidney,
      'comorbidity.chronic-kidney'
    );
    subscribeComorbidity(
      this.comorbiditiesForm.controls.comoEndocrine,
      'comorbidity.endocrine'
    );
    subscribeComorbidity(
      this.comorbiditiesForm.controls.comoLiver,
      'comorbidity.liver'
    );
    subscribeComorbidity(
      this.comorbiditiesForm.controls.comoNeoplasia,
      'comorbidity.neoplasia'
    );
    subscribeComorbidity(
      this.comorbiditiesForm.controls.comoOther,
      'comorbidity.other'
    );

    this.medicationForm.controls.medsFurosemideTorasemide.valueChanges.subscribe(
      (val) => {
        if (val) {
          this.warnings.set(
            'medication.furosemide-torasemide',
            new Warning(
              'medication.furosemide-torasemide',
              '',
              null,
              this.medicationStep,
              WarningType.error
            )
          );
        } else {
          this.warnings.delete('medication.furosemide-torasemide');
        }
      }
    );
    const subscribeMedication = this.subscribeCheckbox(
      'medication.@',
      this.medicationStep
    );
    subscribeMedication(
      this.medicationForm.controls.medsPimobendan,
      'medication.pimobendan'
    );
    subscribeMedication(
      this.medicationForm.controls.medsEndocrine,
      'medication.endocrine'
    );
    subscribeMedication(
      this.medicationForm.controls.medsAntiArrhythmics,
      'medication.anti-arrhythmics'
    );
    subscribeMedication(
      this.medicationForm.controls.medsChemotherapy,
      'medication.chemotherapy'
    );

    this.physicalExamForm.controls.physWeight.valueChanges.subscribe(
      (weight: number) => {
        if (weight < 2) {
          this.warnings.set(
            'physical.weight',
            new Warning(
              'physical.weight.toolow',
              weight.toString(),
              this.elemPhysWeight,
              this.physicalExamStep,
              WarningType.error
            )
          );
        } else if (weight > 25) {
          this.warnings.set(
            'physical.weight',
            new Warning(
              'physical.weight.toohigh',
              weight.toString(),
              this.elemPhysWeight,
              this.physicalExamStep,
              WarningType.error
            )
          );
        } else {
          this.warnings.delete('physical.weight');
        }
      }
    );

    combineLatest([
      this.bloodTestForm.controls.BUN.valueChanges,
      this.bloodTestForm.controls.Creatinine.valueChanges,
    ]).subscribe((e) => {
      const newBUN = e[0];
      const newCreatinine = e[1];

      if (newBUN >= 10.3 && newCreatinine >= 133) {
        this.warnings.set(
          'Azotaemia',
          new Warning('check', null, null, this.bloodExamStep, WarningType.info)
        );
      }
    });
    /*
    this.bloodTestForm.controls[''].valueChanges.subscribe((newFormValue) => {
      if (
        newFormValue === undefined ||
        newFormValue === null ||
        newFormValue === ''
      ) {
        this.warnings.delete(name);
        return;
      }
      if (constraint.min && constraint.min >= newFormValue) {
        this.warnings.set(
          name,
          new Warning(warningName, newFormValue, constraint.elem, step, type)
        );
      } else if (constraint.max && constraint.max <= newFormValue) {
        this.warnings.set(
          name,
          new Warning(warningName, newFormValue, constraint.elem, step, type)
        );
      } else {
        this.warnings.delete(name);
      }
    });
    */

    /*
    [
      { name: 'VHS', min: 9, max: 15, elem: this.elemImageVHS },
      { name: 'VLAS', min: 1, max: 4, elem: this.elemImageVLAS },
      { name: 'LAAo', min: 0, max: 5, elem: this.elemImageLAAo },
      { name: 'LVIDDN', min: 0, max: 4, elem: this.elemImageLVIDDN },
    ].forEach((constraint) => {
      this.addWarning(
        constraint,
        this.imageTestForm,
        'image-test-results.',
        this.imageStep
      );
    });
    */

    [
      { name: 'ALKP', max: 1470, elem: this.elemBloodALKP },
      { name: 'ALT', max: 488, elem: this.elemBloodALT },
      { name: 'Glucose', max: 10, elem: this.elemBloodGlucose },
      { name: 'Calcium', max: 3, elem: this.elemBloodCalcium },
    ].forEach((constraint) => {
      this.addWarning(
        constraint,
        this.bloodTestForm,
        'bt.less.',
        this.bloodExamStep,
        WarningType.info,
        'lessAccurate'
      );
    });

    /*
    [
      { name: 'VHS', min: 9, max: 15, elem: this.elemImageVHS },
      { name: 'VLAS', min: 1, max: 4, elem: this.elemImageVLAS },
      { name: 'LAAo', min: 0, max: 5, elem: this.elemImageLAAo },
      { name: 'LVIDDN', min: 0, max: 4, elem: this.elemImageLVIDDN },
    ].forEach((constraint) => {
      this.addWarning(
        constraint,
        this.imageTestForm,
        'image-test-results.',
        this.imageStep
      );
    });
    */

    const params = await this.activatedRoute.params.pipe(take(1)).toPromise();
    if (params.uid) {
      this.currentlyLoadedCase.pipe(filter((val) => !!val)).subscribe(() => {
        const forms = [
          this.baseDataForm,
          this.patientInfoForm,
          this.comorbiditiesForm,
          this.medicationForm,
          this.historyForm,
          this.physicalExamForm,
          this.bloodTestForm,
          this.imageTestForm,
        ];
        forms.forEach(
          (form) => form.markAllAsTouched()
          // Object.keys(form.controls).forEach((key) =>
          //   form.controls[key].markAllAsTouched()
          // )
        );
        [
          this.baseDataStep,
          this.patientInfoStep,
          this.comorbiditiesStep,
          this.medicationStep,
          this.historyStep,
          this.physicalExamStep,
          this.bloodExamStep,
        ].forEach((step) => (step.interacted = true));
      });
    }
  }

  public addWarning(
    constraint: {
      name: string;
      min?: number;
      max?: number;
      elem: ElementRef;
    },
    form: FormGroup,
    prename: string,
    step: any,
    type: WarningType = WarningType.dismissable,
    warningName: string = 'check'
  ) {
    form.controls[constraint.name].valueChanges.subscribe((newFormValue) => {
      const name = `${prename}${constraint.name}`;
      if (
        newFormValue === undefined ||
        newFormValue === null ||
        newFormValue === ''
      ) {
        this.warnings.delete(name);
        return;
      }
      if (constraint.min && constraint.min > newFormValue) {
        this.warnings.set(
          name,
          new Warning(warningName, newFormValue, constraint.elem, step, type)
        );
      } else if (constraint.max && constraint.max < newFormValue) {
        this.warnings.set(
          name,
          new Warning(warningName, newFormValue, constraint.elem, step, type)
        );
      } else {
        this.warnings.delete(name);
      }
    });
  }

  createCase(): void {
    const caseToCreate: Case = {
      genUID: this.genUID,
      petID: this.baseDataForm.value.petID,
      forename: this.baseDataForm.value.forename,
      surname: this.baseDataForm.value.surname,
      age: {
        years: this.patientInfoForm.value.ageYears,
        months: this.patientInfoForm.value.ageMonths,
      },
      sex: this.patientInfoForm.value.sex,
      breed: this.patientInfoForm.value.breed,
      updatedAt: new Date(),
      createdAt: this.currentlyLoadedCase.getValue()?.createdAt ?? new Date(),
      comorbidities: {
        otherCardiac: this.comorbiditiesForm.value.comoOtherCardiac,
        chronicKidney: this.comorbiditiesForm.value.comoChronicKidney,
        endocrine: this.comorbiditiesForm.value.comoEndocrine,
        liver: this.comorbiditiesForm.value.comoLiver,
        neoplasia: this.comorbiditiesForm.value.comoNeoplasia,
        other: this.comorbiditiesForm.value.comoOther,
        details: this.comorbiditiesForm.value.comoDetails ?? '',
      },
      medication: {
        acei: this.medicationForm.value.medsAcei,
        furosemideTorasemide:
          this.medicationForm.value.medsFurosemideTorasemide,
        pimobendan: this.medicationForm.value.medsPimobendan,
        spironolactone: this.medicationForm.value.medsSpironolactone,
        endocrine: this.medicationForm.value.medsEndocrine,
        antiArrhythmics: this.medicationForm.value.medsAntiArrhythmics,
        chemotherapy: this.medicationForm.value.medsChemotherapy,
        details: this.comorbiditiesForm.value.medsDetails ?? '',
      },
      history: {
        appetite: this.historyForm.value.histAppetite,
        cough: this.historyForm.value.histCough,
        exerciseTolerance: this.historyForm.value.histExerciseTolerance,
      },
      physicalExam: {
        bcs: this.physicalExamForm.value.physBcs,
        heartrate: this.physicalExamForm.value.physHeartrate,
        heartrhythm: this.physicalExamForm.value.physHeartrhythm,
        murmur: this.physicalExamForm.value.physMurmur,
        respiratoryRate: this.physicalExamForm.value.physRespiratoryRate,
        weight: this.physicalExamForm.value.physWeight,
      },
      bloodTestResult: {
        ...this.bloodTestForm.value,
      },
      imageTestResult: {
        ...this.imageTestForm.value,
      },
      completed: false,
      sentMail: false,
    };
    this.store.dispatch(new CasesActions.AddCase(caseToCreate));
    this.router.navigateByUrl('/case-list');
  }

  complete(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titleText: this.translate.instant('case-create.confirm-submit.title'),
        descText: this.translate.instant('case-create.confirm-submit.desc'),
        abortText: this.translate.instant('case-create.confirm-submit.abort'),
        confirmText: this.translate.instant(
          'case-create.confirm-submit.confirm'
        ),
      },
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (!confirmed) {
        return;
      }
      const caseToCreate: Case = {
        genUID: this.genUID,
        petID: this.baseDataForm.value.petID,
        forename: this.baseDataForm.value.forename,
        surname: this.baseDataForm.value.surname,
        age: {
          years: this.patientInfoForm.value.ageYears,
          months: this.patientInfoForm.value.ageMonths,
        },
        sex: this.patientInfoForm.value.sex,
        breed: this.patientInfoForm.value.breed,
        updatedAt: new Date(),
        createdAt: this.currentlyLoadedCase.getValue()?.createdAt ?? new Date(),
        comorbidities: {
          otherCardiac: this.comorbiditiesForm.value.comoOtherCardiac,
          chronicKidney: this.comorbiditiesForm.value.comoChronicKidney,
          endocrine: this.comorbiditiesForm.value.comoEndocrine,
          liver: this.comorbiditiesForm.value.comoLiver,
          neoplasia: this.comorbiditiesForm.value.comoNeoplasia,
          other: this.comorbiditiesForm.value.comoOther,
          details: this.comorbiditiesForm.value.comoDetails,
        },
        medication: {
          acei: this.medicationForm.value.medsAcei,
          furosemideTorasemide:
            this.medicationForm.value.medsFurosemideTorasemide,
          pimobendan: this.medicationForm.value.medsPimobendan,
          spironolactone: this.medicationForm.value.medsSpironolactone,
          endocrine: this.medicationForm.value.medsEndocrine,
          antiArrhythmics: this.medicationForm.value.medsAntiArrhythmics,
          chemotherapy: this.medicationForm.value.medsChemotherapy,
          details: this.medicationForm.value.medsDetails,
        },
        history: {
          appetite: this.historyForm.value.histAppetite,
          cough: this.historyForm.value.histCough,
          exerciseTolerance: this.historyForm.value.histExerciseTolerance,
        },
        physicalExam: {
          bcs: this.physicalExamForm.value.physBcs,
          heartrate: this.physicalExamForm.value.physHeartrate,
          heartrhythm: this.physicalExamForm.value.physHeartrhythm,
          murmur: this.physicalExamForm.value.physMurmur,
          respiratoryRate: this.physicalExamForm.value.physRespiratoryRate,
          weight: this.physicalExamForm.value.physWeight,
        },
        bloodTestResult: {
          ...this.bloodTestForm.value,
        },
        imageTestResult: {
          ...this.imageTestForm.value,
        },
        completed: true,
        sentMail: false,
      };
      this.store.dispatch(new CasesActions.AddCase(caseToCreate));
      this.router.navigateByUrl(`/score/${this.genUID}`);
    });
  }

  openBcsDialog(): void {
    this.dialog.open(BcsDialogComponent);
  }

  openMurmurDialog(): void {
    this.dialog.open(MurmurDialogComponent);
  }

  private updateValidityWarning(): void {
    if (
      (this.baseDataForm.valid &&
        this.patientInfoForm.valid &&
        this.comorbiditiesForm.valid &&
        this.medicationForm.valid &&
        this.historyForm.valid &&
        this.physicalExamForm.valid &&
        this.bloodTestForm.valid) ||
      this.isFormLocked
    ) {
      this.warnings.delete('warn.required');
    } else {
      this.warnings.set(
        'warn.required',
        new Warning('required', '', null, null, WarningType.error)
      );
    }
  }

  private subscribeCheckbox(
    warning: string,
    step: MatStep
  ): (ctrl: AbstractControl, id: string) => void {
    return (ctrl: AbstractControl, id: string) => {
      ctrl.valueChanges.subscribe((val: boolean) => {
        if (val) {
          this.warnings.set(
            id,
            new Warning(
              warning,
              '',
              null, // Pass null for the control. The checkboxes won't be focused anyway
              step,
              WarningType.info
            )
          );
        } else {
          this.warnings.delete(id);
        }
      });
    };
  }

  viewScore() {
    this.router.navigateByUrl(`/score/${this.genUID}`);
  }
}
